.amasty-product-attachment-block {
    padding-left: 0;
    @include lib-vendor-prefix-display(inline-block);

    li {
        padding-left: 0;

        a {
            text-decoration: underline;
        }

        &:before {
            content: url(lib-icon-svg-url($icon-base-download, $theme__secondary__color));
            width: 13px;
            height: 18px;
            margin-right: 11px;
            display: inline-block;
        }
    }
}