//
//  Theme variables
//  _____________________________________________

//  Messages
$message-global-note__background           : $color-yellow-light2 !default;
$message-global-note__border-color         : $color-yellow-light3 !default;
$message-global-note__color                : $text__color !default;

$message-global-note-link__color           : $link__color !default;
$message-global-note-link__color-hover     : $link__hover__color !default;
$message-global-note-link__color-active    : $link__active__color !default;

$message-global-caution__background        : $color-red9 !default;
$message-global-caution__border-color      : none !default;
$message-global-caution__color             : $color-white !default;

$message-global-caution-link__color        : $link__color !default;
$message-global-caution-link__color-hover  : $link__hover__color !default;
$message-global-caution-link__color-active : $link__active__color !default;

//  Header
$header__background-color                  : false !default;
$header-icons-color                        : $color-gray56 !default;
$header-icons-color-hover                  : $color-gray20 !default;

$addto-color                               : $text__color__muted !default;
$addto-hover-color                         : $primary__color !default;

$minicart-icons-color                      : $header-icons-color !default;
$minicart-icons-color-hover                : $header-icons-color-hover !default;

$button__shadow                            : inset 0 2px 1px rgba(0, 0, 0, 0.12) !default;

$h1__margin-bottom__desktop                : $indent__xl !default;

//  Footer
$footer__background-color                  : $color-gray-light0 !default;

body {
    @include lib-css(background-color, $page__background-color);

    &.account,
    &.catalog-product-view,
    &.checkout-cart-index,
    &.checkout-index-index {
        @include lib-css(background-color, $page__detail__background-color);
    }
}

//
//  Header
//  ---------------------------------------------

.page-header {
    @include lib-css(background-color, $header__background-color);
    border-bottom: 1px solid $border-color__base;
    margin-bottom: $indent__base;
}

.header {
    &.content {
        @extend .abs-add-clearfix;
        padding-top: $indent__s;
        position: relative;
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

.account,
.customer-account-create,
.customer-account-forgotpassword,
.customer-account-createpassword,
.cms-noroute-index,
.checkout-cart-index,
.checkout-onepage-success,
.offerte-request-form {
    #maincontent {
        &.page-main {
            margin-top: 170px;
        }
    }
}

.cms-index-index {
    #maincontent {
        &.page-main {
            margin-top: 200px;
        }
    }
}

.customer-account {
    &-login,
    &-logoutsuccess {
        #maincontent {
            &.page-main {
                margin-top: 170px;
            }
        }
    }
}

body {
    &.top-message-active {
        &.customer-account {
            &-login,
            &-logoutsuccess,
            &-forgotpassword {
                #maincontent {
                    &.page-main {
                        margin-top: 205px;
                    }
                }
            }
        }
    }
}

.checkout-index-index {
    #maincontent {
        &.page-main {
            margin-top: 175px;
        }
    }
}

.page-main {
    > .page-title-wrapper {
        .page-title {
            & + .action {
                margin-top: $indent__l;
            }
        }
    }
}

.action {
    &.skip {
        &:not(:focus) {
            @extend .abs-visually-hidden;
        }

        &:focus {
            @include lib-css(background, $color-gray94);
            @include lib-css(padding, $indent__s);
            box-sizing: border-box;
            left: 0;
            position: absolute;
            text-align: center;
            top: 0;
            width: 100%;
            z-index: 15;
        }
    }
    &-skip-wrapper {
        height: 0;
        position: relative;
    }
}


.page-layout-2columns-left {
    .sidebar-main {
        width: 25%;
        padding-right: 0;
    }

    .column.main {
        width: 73%;
        padding-left: 2%;
    }
}

header {
    background-color: $theme__primary__color;
    position: absolute;
    width: 100%;
    z-index: 1000;
    max-height: 100%;

    .logo {
        margin: 0;
        max-width: 100%;
        width: 120px;

        img {
            margin-bottom: 6px;
        }
    }

    .header {
        &.content {
            height: 80px;
            padding: 15px 0 0;
        }
    }

    .col {
        &-logo {
            padding-left: 20px;
        }

        .category-button {
            @include lib-font-size($font-size__base);
            @include lib-css(font-family, $font-family__base);
            position: relative;
            top: 0;
            line-height: $line-height__xs;

            &-title {
                @include lib-font-size($font-size__m);
                font-weight: $font-weight__semibold;
                text-transform: capitalize;
            }

            &-down {
                display: inline-block;
                background-image: url(lib-icon-svg-url($icon-base-arrow-down, $color-white));
                background-size: 10px 10px;
                width: 10px;
                height: 10px;
                margin-left: 7px;
            }
        }
    }

    .header-top-bar-container {
        @include lib-font-size($font-size__s);
        line-height: $line-height__xs;
        background-color: $theme__primary__color__light;
        color: $color-white;
        height: 40px;

        .header-top-bar {
            padding: 13px 0;

            ul {
                list-style: none;
                padding: 0;
                margin: 0;

                li {
                    display: inline-block;
                    margin-right: 19px;
                    margin-bottom: 0;

                    &:before {
                        content: url(lib-icon-svg-url($icon-base-check, $theme__secondary__color));
                        display: inline-block;
                        width: 11px;
                        height: 12px;
                        margin-right: 8px;
                    }
                }
            }
        }

        .header-top-call-us {
            @include lib-vendor-prefix-display(inline-flex);
            height: 40px;
        }

        .nav-reviews {
            text-align: right;
        }
    }

    &.fixed {
        position: fixed;

        .header-top-bar-container,
        .secondary-menu-bar-container {
            display: none;
        }
    }

    .secondary-menu-bar-container {
        background-color: $color-white;
        min-height: 45px;
        position: relative;
        z-index: 1;

        p {
            display: none;
        }

        .secondary-menu-bar-col {
            border-bottom: 1px solid $color-gray96;
        }

        .submenu-overlay {
            position: absolute;
            transition: background-color 0.5s ease;

            &.active {
                top: 0;
                bottom: -1px;
                left: 0;
                right: 0;
                z-index: 99;
                background-color: $theme__transparant-color-dark-darker;
            }
        }
    }
}

.category-menu-wrapper {
    display: none;
    position: absolute;
    top: 60px;

    &.active {
        width: 100%;
        display: block;
    }
}

//
//  Global notice
//  ---------------------------------------------
.messages {
    .message {
        &.global {
            p {
                margin: 0;
            }

            &.noscript,
            &.cookie {
                @include lib-message($_message-type: global-note);
                margin: 0;
            }

            &.cookie {
                bottom: 0;
                left: 0;
                position: fixed;
                right: 0;
                z-index: 3;

                .actions {
                    margin-top: $indent__s;
                }
            }

            &.demo {
                @include lib-message($_message-type: global-caution);
                margin-bottom: 0;
                text-align: center;
            }
        }

        &.success {
            @include lib-font-size($font-size__base);
            margin: 0 0 10px;
            padding: 12px 20px 12px 25px;
            display: block;
            background: $color-gray90;
            color: $theme__primary__color;
            padding-left: 45px;
            position: relative;

            >*:first-child {
                &:before {
                    @include lib-icon-svg($icon-base-check-circle, $color-dark-green1);
                    vertical-align: middle;
                    display: inline-block;
                    left: 10px;
                    top: 17px;
                    width: 20px;
                    height: 27px;
                    position: absolute;
                }
            }
        }
    }
}

//
//  Footer
//  ---------------------------------------------

.page-footer {
    @include lib-css(background-color, $footer__background-color);
}

body.account,
.catalog-product-view {
    .footer {
        &.newsletter {
            padding: 30px 0;
        }
    }
}

.footer {
    &.content {
        width: 100%;
        max-width: 100%;
        padding: 0;

        ul {
            @extend .abs-reset-list;
        }

        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
            display: none;
        }

        p {
            @include lib-font-size($font-size__base);
        }
    }

    &.newsletter {
        background-color: $color-gray96;
        padding: 0 0 30px;

        .newsletter {
            background-color: $color-gray1;
        }
    }

    .copyright,
    .bugs {
        display: block;
        margin: 20px 0 0;
    }

    .social-links {
        text-indent: -9999px;
        display: inline-block;
        width: 35px;
        height: 35px;
        margin-right: 8px;
        background-color: $theme__primary__color;

        &.twitter-icon {
            @include svg-content-background($icon-base-twitter, $theme__secondary__color);
            background-color: $color-gray1;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-twitter, $color-gray1);
                background-color: $theme__secondary__color;
            }
        }

        &.facebook-icon {
            @include svg-content-background($icon-base-facebook, $theme__secondary__color);
            background-color: $color-gray1;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-facebook, $color-gray1);
                background-color: $theme__secondary__color;
            }

        }

        &.instagram-icon {
            @include svg-content-background($icon-base-instagram, $theme__secondary__color);
            background-color: $color-gray1;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-instagram, $color-gray1);
                background-color: $theme__secondary__color;
            }
        }

        &.linkedin-icon {
            @include svg-content-background($icon-base-linkedin, $theme__secondary__color);
            background-color: $color-gray1;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-base-linkedin, $color-gray1);
                background-color: $theme__secondary__color;
            }
        }

        &.whatsapp-icon {
            @include svg-content-background($icon-kuras-whatsapp-square, $theme__secondary__color);
            background-color: $color-gray1;
            transition: all 0.2s ease-out;

            &:hover {
                @include svg-content-background($icon-kuras-whatsapp-square, $color-gray1);
                background-color: $theme__secondary__color;
            }
        }
    }

    &-title {
        @include lib-font-size($font-size__xl);
        @include lib-css(font-weight, $font-weight__bold);
        padding-bottom: 15px;
    }

    &-logo {
        margin-top: 30px;

        &-contact {
            color: $color-white;

            .footer-contact {
                margin-bottom: 30px;
            }

            a {
                color: $color-white;
            }
        }
    }
}

.page-header,
.page-footer {
    .switcher {
        margin-right: 10px;

        .options {
            @include lib-dropdown(
                $_dropdown-actions-padding            : 0,
                $_dropdown-list-item-padding          : 0,
                $_dropdown-toggle-icon-content        : $icon-down,
                $_dropdown-toggle-active-icon-content : $icon-up,
                $_icon-font-text-hide                 : true,
                $_icon-font-size                      : 22px,
                $_icon-font-line-height               : 22px,
                $_dropdown-list-min-width             : 160px
            );

            ul {
                &.dropdown {
                    a {
                        display: block;
                        padding: 8px;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        li {
            @include lib-font-size($font-size__s);
            margin: 0;
        }

        .label {
            @extend .abs-visually-hidden;
        }

        strong {
            font-weight: $font-weight__regular;
        }
    }
}

//
//  Widgets
//  ---------------------------------------------

.sidebar {
    .widget {
        &.block {
            &:not(:last-child) {
                @extend .abs-margin-for-blocks-and-widgets;
            }
        }
        &:not(:last-child) {
            @extend .abs-margin-for-blocks-and-widgets;
        }
    }
}

.widget {
    clear: both;

    .block-title {
        @extend .abs-block-title;
    }
}

.page-header,
.page-footer {
    .widget {
        &.block {
            @include lib-css(margin, $indent__base 0);
        }
    }
}

.no-display {
    @extend .abs-no-display;
}

//
//  Calendar
//  ---------------------------------------------

.ui-datepicker {
    td {
        padding: 0;
    }
}

//
//  Form
//  _____________________________________________

.control {
    position: relative;

    select,
    input[type="text"] {
        @include lib-vendor-prefix-appearance(none);
        border: 1px solid $color-gray82;
        height: 45px;
        padding-right: 25px;
        text-indent: .01em;
        text-overflow: clip;
        white-space: nowrap;
        overflow: hidden;
        border-radius: 0;
        padding-left: 11px;
        width: 100%;
    }
}

input[type="text"] {
    &:focus {
        border-bottom: 2px solid $theme__secondary__color;
    }
}

select {
    @include lib-vendor-prefix-appearance(none);
    background-size: 30px 60px;
    background: $color-white url(lib-icon-svg-url($icon-base-caret-down, $color-gray20)) no-repeat right 15px center;
}


.icon-arrow-right {
    position: absolute;
    right: 25px;
    top: 15px;
    width: 20px;
    height: 14px;
    display: inline-block;
    background: url(lib-icon-svg-url($icon-base-arrow-right, $color-gray1)) no-repeat;
    background-position: center;
    margin-left: 11px;
}

.link-with-icon {
    .icon-arrow-right {
        right: 0;
        top: 0;
        width: 70px;
        height: 100%;
        background-color: $theme__secondary__color;
        transition: all 0.2s ease-out;

        &.primary {
            background: url(lib-icon-svg-url($icon-base-arrow-right, $theme__primary__color)) no-repeat;
        }

        &:hover {
            background: url(lib-icon-svg-url($icon-base-arrow-right, $theme__secondary__color)) no-repeat;
            background-color: $color-gray1;
            background-position: center;
        }
    }
}

.banner-button {
    @include lib-button-banner();
    position: relative;

    &:visited {
        color: $theme__primary__color;
    }

    &:hover {
        text-decoration: none;
        color: $color-white;

        .icon-arrow-right {
            background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
            background-position: center;
        }
    }

    &-secondary {
        @include lib-button-banner-secondary();
        position: relative;

        &:visited {
            color: $theme__primary__color;
        }

        &:hover {
            text-decoration: none;
            color: $color-white;

            .icon-arrow-right {
                background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
                background-position: center;
            }
        }
    }

    &-revert {
        @include lib-button-banner-revert();
        position: relative;

        .icon-arrow-right {
            background: url(lib-icon-svg-url($icon-base-arrow-right, $color-white)) no-repeat;
            background-position: center;
        }

        &:visited {
            color: $color-white;
        }

        &:hover {
            text-decoration: none;
            color: $theme__primary__color;

            .icon-arrow-right {
                background: url(lib-icon-svg-url($icon-base-arrow-right, $theme__primary__color)) no-repeat;
                background-position: center;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {

    html,
    body {
        height: 100%; // Stretch screen area for sticky footer
    }

    .page-wrapper {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-direction(column);
        min-height: 100%; // Stretch content area for sticky footer

        > .breadcrumbs,
        > .top-container,
        > .widget {
            box-sizing: border-box;
            width: 100%;
        }

        .ie10 &,
        .ie11 & {
            height: 100%;
        }
    }

    .navigation {
        ul {
            padding: 0 8px;
        }
    }

    .header {
        &.panel {
            > .header {
                &.links {
                    @include lib-list-inline();
                    @include lib-font-size(0);
                    float: right;
                    margin-left: auto;

                    > li {
                        @include lib-font-size($font-size__base);
                        margin: 0 0 0 15px;

                        &.welcome,
                        a {
                            line-height: 1.4;
                        }

                        &.welcome {
                            a {
                                @include lib-css(padding-left, $indent__xs);
                            }
                        }
                    }
                }
            }
        }

        &.content {
            @extend .abs-add-clearfix-desktop;
            padding: $indent__l $indent__base 0;
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel {
            &.wrapper {
                border-bottom: 1px solid $secondary__color;
            }
        }

        .header {
            &.panel {
                @extend .abs-add-clearfix-desktop;
                padding-bottom: $indent__s;
                padding-top: $indent__s;
            }
        }

        .switcher {
            display: inline-block;
        }
    }

    .page-main {
        > .page-title-wrapper {
            .page-title {
                display: inline-block;

                & + .action {
                    float: right;
                    margin-top: $indent__base;
                }
            }
        }
    }

    .logo {
        margin: -8px auto 25px 0;

        img {
            max-height: inherit;
        }
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher {
                &.store {
                    display: none;
                    padding-right: 50px;
                    vertical-align: top;
                }
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }
}

//
//  tablet
//  _____________________________________________

@include screen($screen__m, $screen__l, $orientation: portrait) {
    .account,
    .catalog-category-view,
    .catalog-product-view,
    .cms-noroute-index,
    .checkout-cart-index,
    .checkout-onepage-success {
        #maincontent {
            &.page-main {
                margin-top: 0;
            }
        }
    }

    .customer-account-create,
    .customer-account-forgotpassword,
    .cms-index-index {
        #maincontent {
            &.page-main {
                margin-top: 100px;
            }
        }
    }

    .header-top-bar-container,
    .secondary-menu-bar-container {
        display: none;
    }

    .checkout-index-index {
        #maincontent {
            &.page-main {
                margin-top: 100px;
            }
        }
    }
}

.no-margin {
    margin-left: 0;
    margin-right: 0;
}

@include max-screen($screen__m) {
    .page-header {
        top: 0;
    }

    header {
        .header {
            &.content {
                @include lib-vendor-prefix-display(flex);
                margin: 0;
                height: 65px;
                padding: 0;

                .col {
                    &-logo {
                        @include lib-vendor-prefix-order(2);
                        padding: 0 13px 0 0;

                        .logo {
                            margin-top: 3px;
                        }
                    }
                }
            }
        }
    }

    .page-header {
        top: 0;
        margin-bottom: 0;
    }

    .account-link {
        &:after {
            display: none;
        }

        &-label {
            &:after {
                display: inline-block;
            }

            &:hover {
                &:after {
                    display: inline-block;
                }
            }

            span {
                display: none;
            }

            &.mobile-not-logged-in {
                display: none;
            }
        }

        &-direct {
            display: block;
        }
    }

    .col {
        &-menu {
            @include lib-vendor-prefix-order(1);
        }

        &-minicart {
            @include lib-vendor-prefix-order(4);
        }

        &-search {
            position: absolute;
            top: 65px;
            width: 100%;
            background: $color-white;
            border-bottom: 1px solid $theme__primary__border__color;
        }
    }

    .header-account {
        @include lib-vendor-prefix-order(3);
    }

    .account,
    .catalog-category-view,
    .catalog-product-view,
    .cms-noroute-index,
    .checkout-onepage-success {
        #maincontent {
            &.page-main {
                margin-top: 0;
            }
        }
    }

    .customer-account-create,
    .customer-account-forgotpassword,
    .cms-index-index {
        #maincontent {
            &.page-main {
                margin-top: 170px;
            }
        }
    }

    .checkout-index-index,
    .checkout-cart-index {
        #maincontent {
            &.page-main {
                margin-top: 110px;
            }
        }
    }

    .account {
        #maincontent {
            &.page-main {
                margin-top: 70px;
            }
        }
    }

    .grecaptcha-badge {
        display: none;
    }

    .website-by-link {
        line-height: 1.6;
    }
}
