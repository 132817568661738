.block {
  &.ves-widget {
    .block-title {
      margin-bottom: 28px;
      padding-top: 8px;

      strong {
        @include lib-font-size($font-size__xxxl);
        @include lib-css(font-family, $font-family__base);
        line-height: $line-height__xs;
        font-weight: $font-weight__bold;
      }
    }

    &.menu-title {
      padding-bottom: 24px;
      margin: 15px 0 24px;
      border-bottom: 1px solid $color-gray82;

      .block-content {
        @include lib-css(font-family, $font-family__base);
        font-weight: $font-weight__semibold;
        @include lib-font-size($font-size__xxl);
      }
    }

    .block-content {
      font-weight: $font-weight__regular;
    }
  }
}

.block-title-half {
  margin-bottom: 28px;
  padding-top: 8px;

  &:after {
    content: "";
    display: block;
    width: calc(50% - 15px);
    padding-top: 23px;
    border-bottom: 6px solid $theme__secondary__color;
  }

  strong {
    @include lib-font-size($font-size__xxxl);
    @include lib-css(font-family, $font-family__base);
    line-height: $line-height__xs;
    font-weight: $font-weight__bold;
  }
}

.block-title-wide {
  margin-bottom: 10px;
  padding-top: 15px;

  &:after {
    content: "";
    display: block;
    width: 100%;
    padding-top: 23px;
    border-bottom: 6px solid $theme__secondary__color;
  }
}

.cms-homepage {
  .block {
    &.ves-widget {
      .block-title {
        border-bottom: 6px solid $theme__secondary__color;
        padding-bottom: 20px;
        margin-top: 16px;
      }
    }

    &.block-products-list {
      margin-bottom: 0;

      .block-title {
        margin-top: 16px;
        padding-bottom: 20px;

        strong {
          font-weight: $font-weight__semibold;
          @include lib-font-size($font-size__xxxl);
        }
      }

      .block-content {
        margin-left: -15px;
        margin-right: -15px;
        padding: 0;
        display: block;

        .product-items {
          margin: 0;
        }
      }
    }
  }

  .homepage-banners {
    .block {
      &.ves-widget {
        .block-content {
          @include lib-font-size($font-size__xxxl);
          @include lib-css(font-family, $font-family__base);
          @include lib-css(font-weight, $font-weight__bold);
        }
      }
    }
  }

  .homepage-tips-container {
    .block {
      &.ves-widget {
        margin-bottom: 0;

        .content {
          @include lib-font-size($font-size__m);
          @include lib-css(font-family, $font-family__base);
          line-height: $line-height__s;
          font-weight: $font-weight__regular;
          padding: 20px 12px 10px 15px;
          color: $theme__primary__color;
          position: inherit;
          height: auto;

          .title {
            position: relative;
            z-index: 1;

            strong {
              @include lib-font-size($font-size__xxl);
              @include lib-css(font-weight, $font-weight__bold);
              line-height: $line-height__xs;
              word-wrap: break-word;
            }
          }

          a {
            @include lib-font-size($font-size__l);
            @include lib-css(font-weight, $font-weight__bold);
            color: $theme__primary__color;
          }
        }

        .link-with-icon {
          @include lib-vendor-prefix-display(flex);
          @include lib-vendor-box-align(center);
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50px;
          padding-left: 18px;
          border-top: 1px solid $theme__primary__border__color;

          .icon-arrow-right {
            width: 50px;
          }
        }
      }
    }

    .content-container {
      background-color: $color-white;
      padding: 0;
    }
  }
}

.homepage-blok {
  .small {
    position: relative;
    margin-bottom: 30px;

    &:hover {
      box-shadow: 0 0 10px 0 $color-gray-light3;

      .subtitle {
        background-color: $theme__secondary__color;
        color: $color-black;
      }
    }

    .content {
      min-height: initial;
      width: 100%;
      padding: 0;

      &:after {
        content: "";
        display: block;
        padding-bottom: 100%;
      }
    }

    .subtitle {
      font-weight: $font-weight__bold;
      @include lib-font-size($font-size__l);
      position: absolute;
      bottom: 0;
      width: 100%;
      background-color: $color-black;
      color: $color-white;
      text-align: center;
      padding: 15px 0;
    }
  }
}

.cms-no-route {
  .block {
    &.ves-widget {
      &.content {
        .block-title {
          text-align: center;
          margin-top: 66px;
        }

        .block-content {
          @include lib-css(font-family, $font-family__base);
          margin-top: 40px;
          text-align: center;
          font-weight: $font-weight__bold;
          @include lib-font-size($font-size__l);

          img {
            margin-bottom: 86px;
          }
        }
      }

      &.actions {
        .block-content {
          @include lib-vendor-prefix-display(flex);
          justify-content: center;
          text-align: center;

          a {
            @include lib-css(font-family, $font-family__base);
            display: inline-block;
            font-weight: $font-weight-_heavier;
            @include lib-font-size($font-size__m);
            padding: 12px 0;
            width: 164px;
            text-transform: uppercase;
            margin: 0 15px;
          }

          a:first-child {
            @include lib-button-secondary(
              $_button-margin: false
            );
          }

          a:not(:first-child) {
            @include lib-button-primary();
          }
        }
      }
    }
  }
}

.widget-singleproduct {
    .product-item-info {
        .actions-primary {
            width: 100%;
        }
    }
}

@include max-screen($screen__xs) {
  .cms-no-route {
    .block {
      &.ves-widget {
        &.actions {
          .block-content {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-direction(column);
            @include lib-vendor-box-align(center);

            a {
              margin-bottom: 15px;
            }
          }
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  .cms-homepage {
    .block {
      &.block-products-list {
        .widget-product-grid {
          li {
            padding: 0;

            .product-item-info {
              border: 0;
            }

            .product-item-actions {
              margin: 0 0 10px 0;
            }

            &:last-of-type {
              margin-bottom: 30px;
            }
          }
        }

        .block-content {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }

    .content-container {
      margin-left: 15px;
    }

    .owl-carousel {
      &.owl-loaded {
        @include lib-vendor-prefix-display(grid);
      }
    }

    .homepage-banners {
      .block {
        &.ves-widget {
          .block-content {
            @include lib-font-size($font-size__xl);
          }
        }
      }
    }
  }
}
