.filter {
    margin-top: 13px;
    &-options {
        &-item {
            &.active {
                .filter-options-title {
                    &:after {
                        @include lib-icon-svg($icon-base-arrow-up, $theme__primary__color__light);
                    }
                }
            }

            .filter-options-title {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__bold);
                line-height: $line-height__l;
                cursor: pointer;

                &:after {
                    width: 13px;
                    height: 17px;
                    @include lib-icon-svg($icon-base-arrow-down, $theme__primary__color__light);
                    margin-left: 13px;
                    display: inline-block;
                    position: absolute;
                    margin-top: -2px;
                }
            }
        }

        &.category-filter {
            .filter-options-title {
                &:after {
                    display: none;
                }
            }
        }
    }

    &-title,
    &-subtitle {
        display: none;
    }

    &-options-content {
        margin-bottom: 30px;

        .items {
            padding: 0;
            list-style: none;

            .item {
                margin: 5px 0 11px 0;
                cursor: pointer;

                input[type=checkbox] {
                    display: none;
                }

                label {
                    @include lib-font-size($font-size__base);
                    padding-left: 15px;
                    color: $catalog-text-color;
                    cursor: pointer;
                }

                .count {
                    color: $color-gray-middle3;

                    &:before {
                        content: '(';
                        display: inline-block;
                    }

                    &:after {
                        content: ')';
                        display: inline-block;
                    }
                }

                .field {
                    &.search {
                        display: none;
                    }
                }
            }
        }

        .actions {
            margin-top: 5px;
            margin-left: 2px;
            display: inline-block;

            .action {
                &.show-more,
                &.show-less {
                    cursor: pointer;

                    &:before {
                        display: none;
                    }

                    &:hover:after,
                    &:after {
                        @include lib-icon-svg($icon-base-arrow-down, $color-gray48);
                        width: 12px;
                        height: 9px;
                        display: inline-block;
                        margin-left: 41px;
                        margin-top: -2px;
                    }

                    span {
                        @include lib-font-size($font-size__s);
                        @include lib-css(font-family, $font-family__base);
                        @include lib-css(font-weight, $font-weight__regular);
                        color: $color-gray-middle3;
                    }
                }
            }

            .action {
                &.show-less {
                    &:hover:after,
                    &:after {
                        @include lib-icon-svg($icon-base-arrow-up, $color-gray48);
                    }
                }
            }
        }
    }

    .specifications {
        .item {
            a {
                @include lib-vendor-prefix-display(flex);
                color: $catalog-text-color;
                padding: 0;
                margin: 0;
                position: relative;

                &:before {
                    @include lib-icon-svg($icon-base-add-to-compare, $color-gray60);
                    top: 2px;
                    position: relative;
                    background: $color-white;
                    flex: 0 0 15px;
                    height: 15px;
                }

                &:hover,
                &.filter-selected {
                    &:after {
                        @include lib-icon-svg($icon-base-check, $color-gray20);
                        width: 10px;
                        height: 9px;
                        display: inline-block;
                        left: 3px;
                        top: -2px;
                        position: absolute;
                    }
                }
            }

        }
    }
}

.category-filter {
    .current,
    .current:hover {
        @include lib-font-size($font-size__base);
        @include lib-css(font-weight, $font-weight__bold);
        color: $theme__primary__color;
        margin: 5px 0 2px 0;
        display: block;

        &:before,
        &:after {
            content: '';
            display: none;
        }
    }

    .items {
        border-left: 1px solid $catalog-border-color-middle;
        margin-left: 10px;
        padding-left: 10px;
    }

    .item {
        a,
        a:hover {
            color: $catalog-text-color;

            &:before,
            &:after {
                content: '';
                display: none;
            }

            .count {
                padding-left: 5px;
            }

            &.active {
                color: $theme__primary__color;
                @include lib-css(font-weight, $font-weight__bold);
            }
        }
    }
}

.filter-current {
    display: inline-block;
    margin-bottom: 20px;

    .block-subtitle {
        @include lib-css(font-weight, $font-weight__regular);
    }

    .items {
        display: inline-block;
        margin-left: 14px;
    }

    .item {
        padding: 5px 11px 6px 25px;
        position: relative;
        z-index: 1;
        list-style: none;
        display: inline-block;
        background: $color-white-fog;
        margin-right: 6px;
    }

    .filter {
        &-value {
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            line-height: $line-height__s;
            padding-right: 18px;
        }

        &-label {
            display: none;
        }
    }

    .remove {
        position: relative;
        left: 0;
        top: 0;

        &:before {
            @include lib-icon-svg($icon-base-close, $color-gray20);
            position: relative;
            left: 0;
            top: 0;
            width: 9px;
            height: 12px;
            display: inline-block;
        }

        &:hover {
            &:after {
                display: none;
            }
        }

        span {
            display: none;
        }
    }
}

.filter-actions {
    display: inline-block;
    padding-left: 11px;
}

.filter-clear,
.filter-clear:visited {
    color: $color-gray48;
    text-decoration: underline;
}
