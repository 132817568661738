.breadcrumbs {
  @include lib-breadcrumbs();
  height: 44px;
  margin-top: 146px;
  margin-bottom: 0;

  strong {
    @include lib-css(font-weight, $font-weight__bold);
  }

  ul {
    li {
      @include lib-css(font-family, $font-family__base);
      @include lib-font-size($font-size__base);
      @include lib-css(font-weight, $font-weight__semibold);
      line-height: $line-height__xl;
      color: $color-gray20;

      a,
      a:visited {
        @include lib-css(font-weight, $font-weight__regular);
        color: $color-gray20;
      }
    }
  }

  .items {
    @include lib-css(font-family, $font-family__base);
    @include lib-vendor-prefix-display(flex);

    .item {
      height: 44px;
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-box-align(center);

      &:not(:last-child) {
        &:after {
          transform: rotate(-90deg);
          content: '\e607';
          line-height: $line-height__s;
          margin-right: 0;
          margin-top: 6px;
          width: 18px;
        }
      }

      &-minicart {
        padding-right: 20px;
        max-width: 104px;
        align-self: end;
        float: right;
      }
    }
  }

  @if $breadcrumbs-separator__symbol != '' and $breadcrumbs-separator__symbol != false {
    .item:not(:last-child) {
      &:after {
        width: 18px;
      }
    }
  }
}

@include screen($screen__m, $screen__l, $orientation: portrait) {
    .breadcrumbs {
        margin-top: 50px;
    }
}

@include max-screen($screen__m) {
    .breadcrumbs {
        height: 15px;

        .items {
            width: 100%;
            display: inline-block;

            .item {
                height: auto;
                line-height: 12px;
                display: none;
                position: relative;

                a {
                    margin-left: 20px;
                }

                &:nth-last-child(2) {
                    display: inline-block;
                }

                &:not(:last-child):after {
                    transform: rotate(90deg);
                    line-height: 9px;
                    margin-top: 0;
                    position: absolute;
                    top: -1px;
                    left: 0;
                }
            }
        }
    }
}

.breadcrumb-spacer {
    height: 24px;
}
