//
//  Product Lists
//  _____________________________________________

.products {
    margin: $indent__l 0;

    &-crosssell {
        .product {
            &-item {
                &-info {
                    .actions-primary {
                        position: relative;
                        bottom: unset;
                        width: 100%;
                    }
                }
            }
        }
    }
}
.product {
    &-items {
        @extend .abs-reset-list;
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-prefix-flex-wrap(wrap);
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;
        width: 100%;

        .products-grid & {
            display: inline-block;
            width: 100%;
        }

        &-name {
            @include lib-font-size($font-size__m);
            font-weight: $font-weight__semibold;
            line-height: $line-height__s;
            display: block;
            min-height: 65px;

            a {
                color: $theme__primary__color;
            }
        }

        &-info {
            width: 100%;
            height: 100%;
            overflow: hidden;

            .actions-primary {
                position: absolute;
                bottom: 0;
                width: calc(100% - 30px);
                background-color: $color-white;
            }
        }

        &-actions {
            display: none;

            .actions-secondary {
                & > .action {
                    @include lib-button-checkout();

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        &-description {
            margin: $indent__m 0;
        }

        .primary {
            @include lib-button-checkout();
            color: $theme__primary__color;
            width: 100%;

            &.configurable,
            &.view-product {
                height: 45px;
                text-align: center;
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                @include lib-font-size($font-size__base);
                margin-top: 5px;
                text-transform: lowercase;

                a {
                    color: $theme__primary__color;

                    span {
                        &:first-of-type {
                            padding-right: 5px;
                        }
                    }
                }
            }
        }

        .price-box {
            @include lib-vendor-prefix-display(flex);
            flex-wrap: wrap;
            align-items: baseline;
            margin-top: 15px;
            color: $color-gray20;

            .price-label {
                @include lib-font-size($font-size__s);
                display: none;
            }
        }

        .special-price,
        .minimal-price {
            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .price-on-request {
            @include lib-font-size($font-size__m);
            font-weight: $font-weight__light;
            line-height: 29px;
        }

        .old-price {
            margin: 0 10px 0 0;

            .price {
                @include lib-font-size($font-size__m);
                font-weight: $font-weight__bold;
                text-decoration: line-through;
                color: $color-gray48;
            }
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .price-ex-vat {
            margin-left: 5px;
        }

        .price-incl-vat {
            @include lib-font-size($font-size__base);
            color: $color-gray62;
            width: 100%;

            .price-label {
                display: inline-block;
                @include lib-font-size($font-size__s);

                &.as-low-as {
                    margin-right: 5px;
                }
            }
        }

        .price-excl-vat {
            @include lib-font-size($font-size__xl);
            font-weight: $font-weight__bold;
            color: $primary__color;

            .price-final_price {
                margin-left: -3px;
                display: inline-block;
            }
        }

        .tocompare {
            @include lib-font-size($font-size__s);
            font-weight: $font-weight__regular;
            color: $primary__color;
            display: block;
            margin-top: 15px;
            position: relative;

            span {
                margin-left: 21px;
            }

            &:before {
                @include lib-icon-svg($icon-base-add-to-compare, $color-gray61);
                display: inline-block;
                width: 15px;
                height: 15px;
                position: absolute;
                margin-top: 0;
            }

            &:hover {
                &:after {
                    @include lib-icon-svg($icon-base-check, $color-gray20);
                    width: 10px;
                    height: 9px;
                    display: inline-block;
                    left: 3px;
                    top: -2px;
                    position: absolute;
                }
            }
        }

        .box-tocart {
            margin: 15px 0 0;

            .fieldset {
                .field,
                .actions {
                    padding: 0;
                }
            }

            .tocart {
                white-space: nowrap;
                padding: 5px 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                height: 45px;

                span {
                    display: none;
                }

                i {
                    &.cart-icon {
                        &:before {
                            @include lib-icon-svg($icon-base-cart, $theme__primary__color);
                            display: inline-block;
                            height: 22px;
                            width: 24px;
                            position: relative;
                        }
                    }

                    &.plus-cart-icon {
                        &:before {
                            @include lib-icon-svg($icon-base-add-to-cart, $theme__primary__color);
                            display: inline-block;
                            height: 11px;
                            width: 11px;
                            margin-right: 5px;
                            position: relative;
                        }
                    }
                }

                &.disabled {
                    i {
                        &.cart-icon {
                            &:before {
                                @include lib-icon-svg($icon-base-cart, $color-white);
                                display: inline-block;
                                height: 22px;
                                width: 24px;
                                position: relative;
                            }
                        }

                        &.plus-cart-icon {
                            &:before {
                                @include lib-icon-svg($icon-base-added-to-cart, $color-white);
                                display: inline-block;
                                height: 11px;
                                width: 11px;
                                margin-right: 5px;
                                position: relative;
                            }
                        }
                    }
                }
            }
        }

        .product-image-container {
            display: block;
            max-width: 100%;
            position: relative;
            margin: 0 auto;
            padding: 15px 0;
        }

        .short-description {
            display: none;
        }

        .stock {
            @include lib-font-size($font-size__s);
            @include lib-css(font-family, $font-family__base);
            color: $color-green2;
            font-weight: $font-weight__regular;

            &.available {
                &:before {
                    @include lib-icon-svg($icon-base-check-circle, $color-green3);
                    width: 12px;
                    margin-right: 7px;
                    display: inline-block;
                }
            }

            &.unavailable {
                color: $color-orange1;

                &:before {
                    @include lib-icon-svg($icon-base-check-circle, $color-orange1);
                    width: 12px;
                    margin-right: 7px;
                    display: inline-block;
                }
            }

            &.request {
                &:before {
                    width: 12px;
                    margin-right: 7px;
                }
            }
        }

        .product-item {
            &-info {
                background-color: $color-white;

                .labels-container {
                    position: absolute;
                    right: 15px;
                    top: 0;
                    z-index: 99;

                    .label {
                        @include lib-font-size($font-size__xs);
                        @include lib-css(font-weight, $font-weight__bold);
                        @include lib-css(padding, 10px 18px);
                        text-transform: uppercase;
                        height: 40px;
                        line-height: $line-height__xl;
                        float: right;
                        clear: both;
                        margin-bottom: 10px;

                        &.onze-keuze {
                            background: $product-label-1;
                            color: $color-white;
                        }

                        &.nieuw {
                            background: $product-label-2;
                            color: $color-white;
                        }

                        &.aanbieding,
                        &.outlet {
                            background: $product-label-3;
                            color: $theme__primary__color;
                        }
                    }
                }
            }

            &-details {
                border-top: 1px solid $theme__primary__border__color;
                padding: 21px 25px 0;
                min-height: 155px;
                margin-bottom: 60px;
            }
        }

        &:hover {
            .product-item {
                &-info {
                    box-shadow: 0 0 10px 0 $color-gray-light3;
                }

                &-actions {
                    display: block;
                    position: absolute;
                    width: calc(100% - 9px);
                    left: 0;
                    z-index: 100;
                    margin-top: 10px;
                    margin-left: 5px;
                    border: 1px solid $color-gray-light7;
                    border-top: 0;
                    box-shadow: 0 2px 8px 0 $theme__transparant-color-dark-middle;
                }
            }
        }

        .qty-plus,
        .qty-minus {
            width: 33.33%;
            height: 44px;
            float: left;
            cursor: pointer;
            text-align: center;

            div {
                width: 12px;
                height: 44px;
                display: inline-block;
            }
        }

        .qty-plus {
            border-left: 1px solid $theme__primary__border__color;

            div {
                background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray20)) no-repeat center center;
            }

            &:hover {
                div {
                    background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
                }
            }
        }

        .qty-minus {
            border-right: 1px solid $theme__primary__border__color;

            div {
                background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray20)) no-repeat center center;
            }

            &:hover {
                div {
                    background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
                }
            }
        }

        .fieldset {
            .field {
                &.qty {
                    margin-bottom: 0;
                }
            }

            .control {
                &.qty {
                    width: 100%;
                    margin: 0;
                    height: 45px;
                    border-top: 1px solid $theme__primary__border__color;

                    .input-text {
                        &.qty {
                            @include lib-font-size($font-size__m);
                            @include lib-css(font-weight, $font-weight__semibold);
                            width: 33.33%;
                            height: 43px;
                            float: left;
                            border: 0;
                            vertical-align: top;
                            margin: 0;
                            padding: 0;
                            color: $catalog-text-color;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}

.products-grid {
    &.wishlist {
        .product-item-info {
            .actions-primary {
                width: 100%;
            }
        }
    }
}

.column.main {
    .product {
        &-items {
            margin-left: -$indent__base;

            li {
                margin-bottom: 30px;

                &.usp-bar-plp {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.price-container {
    .price {
        @include lib-font-size($font-size__xl);
    }

    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .price-including-tax + .price-excluding-tax,
    .weee,
    .price-including-tax + .price-excluding-tax .price,
    .weee .price,
    .weee + .price-excluding-tax:before,
    .weee + .price-excluding-tax .price {
        @include lib-font-size($font-size__xs);
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .product {
        &-item {
            display: table;
            width: 100%;

            &-info {
                display: table-row;
            }

            &-details {
                display: table-cell;
                vertical-align: top;
            }
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

.category-additional-content {
    padding: 0 15px;
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }
}

@include max-screen($screen__m) {
    .column.main {
        .products-grid {
            .product-items {
                margin-left:  0;

                li {
                    margin-bottom: 15px;

                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .product-item,
    .product-item:hover {
        .product-item {
            &-info {
                box-shadow: none;
                border: 0;
                margin: 0;
                padding: 0;
            }

            &-actions {
                position: relative;
                display: block;
                margin: 15px 0;
                box-shadow: none;
                width: 100%;
                border: 0;
            }

        }
    }

    .product-item {
        .product {
            &-item-details {
                min-height: 150px;
            }

            &-image-container {
                padding: 15px 0 0;
            }
        }
    }
}

@include min-screen($screen__s) {
    .product {
        &-item {
            .products-grid & {
                margin-bottom: $indent__l;
            }

            &-actions {

                .products-grid & {
                    margin: $indent__s 0;
                }

                .actions-primary + .actions-secondary {
                    display: table-cell;
                    padding-left: 5px;
                    white-space: nowrap;
                    width: 50%;
                    & > * {
                        white-space: normal;
                    }
                }

                .actions-primary {
                    display: inline-block;
                    width: 100%;
                }
            }
        }
    }

    .page-products,
    .page-layout-1column,
    .page-layout-3columns,
    .page-products.page-layout-1column,
    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .page-products {
        .products-grid {
            .product-item {
                width: 100%;
                height: 100%;
                margin-bottom: 0;
            }
        }
    }

    .page-products.page-layout-1column {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products.page-layout-3columns {
        .products-grid {
            .product-item {
                width: 100%;
            }
        }
    }
}

@include min-screen($screen__l) {
    .page-layout-1column {
        .products-grid {
            .product-item {
                width: 100%;
            }
        }
    }

    .page-layout-3columns {
        .products-grid {
            .product-item {
                width: 25%;
            }
        }
    }

    .page-products {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                margin-left: calc((100% - 4 * 23.233%) / 3);
                padding: 0;

                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }

    .page-products {
        &.page-layout-1column {
            .products-grid {
                .product-item {
                    margin-left: 0;
                    width: 20%;
                }
            }
        }

        &.page-layout-3columns {
            .products-grid {
                .product-item {
                    margin-left: 1%;
                    width: 32.667%;

                    &:nth-child(3n) {
                        margin-left: 1%;
                    }

                    &:nth-child(3n + 1) {
                        margin-left: 0;
                    }
                }
            }
        }
    }
}
