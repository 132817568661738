.block-product-link,
.block-category-link {
    &.widget {
        display: block;
        margin-bottom: $indent__base;
    }
}

.block-product-link-inline {
    &.widget {
        margin: 0;
    }
}

.block {
    &.widget {
        .product-item-info {
            width: auto;
        }

        .pager {
            padding: 0;

            .toolbar-amount {
                float: none;
                @include lib-font-size(12);
            }

            .pages-item-previous {
                padding-left: 0;
            }

            .pages-item-next {
                position: relative;
            }

            .items {
                white-space: nowrap;
            }
        }

        .block-title {
            border-bottom: 1px solid $color-gray64;
            padding-bottom: 22px;
            margin-bottom: 37px;
            margin-top: 40px;

            strong {
                @include lib-font-size($font-size__xl);
                @include lib-line-height($font-size__s);
                @include lib-css(font-family, $font-family__base);
                font-weight: $font-weight__bold;
            }
        }

        &.block-viewed-products-grid {
            a.tocart {
                text-align: center;
            }

            .block-title {
                border: 0;
                padding: 40px 20px 0;
            }

            .products-grid {
                .product-items {
                    li {
                        &.product-item {
                            .product-item-info {
                                position: relative;
                            }

                            &:hover {
                                .product-item-actions {
                                    width: 100%;
                                    margin-left: 0;
                                }
                            }
                        }
                    }
                }

                .product-item {
                    .minimal-price-link {
                        display: inline-block;

                        .price {
                            @include lib-font-size($font-size__xl);
                            font-weight: $font-weight__bold;
                        }
                    }
                }
            }
        }

        .products-related,
        .products-upsell {
            .product-items {
                margin-left: -15px;
                margin-right: -15px;
            }

            .product-item {
                box-shadow: none;
                background-color: $color-white;
                border: 1px solid $theme__primary__border__color;

                .product-item-details {
                    border-top: 0;
                    min-height: 155px;
                    padding: 0 25px 0;

                    strong .product-item-link {
                        font-weight: 700;
                    }
                }

                .product-item-actions {
                    display: block;
                    position: unset;
                    width: 100%;
                    left: 0;
                    z-index: 2;
                    margin: 0;
                    border: 0;
                    border-top: 0;
                    box-shadow: none;

                    a {
                        color: $theme__primary__color;
                        line-height: 20px;
                    }
                }

                &:hover {
                    box-shadow: 0 0 10px 0 $color-gray-light3;

                    .product-item-info {
                        box-shadow: none;
                    }
                }

                .stock {
                    padding-left: 0;
                }
            }
        }

        &.block-new-products {
            .product-item {
                .product-image-container {
                    margin: 0;
                }

                &-details {
                    width: 100%;
                }
            }

            .action-col {
                float: left;
                width: 100%;
                padding: 15px 0;
            }
        }

        .stock {
            padding: 5px 15px 0 0;
            display: block;
        }
    }
}

.widget-product-grid {
    .price-incl-vat {
        .price-label {
            margin-right: 5px;
            display: inline-block;
        }
    }

    .minimal-price-link {
        .price-label {
            display: none;
        }
    }
    .product-item {
        .price-excl-vat {
            .price-final_price {
                margin-left: 0;
            }
        }
    }
}

.column.main {
    .product-items {
        &.widget-new-list,
        &.widget-brands-product-grid {
            margin: 0;

            .stock {
                padding-left: 0;
            }
        }
    }
}

.amfaq_questions {
    &.widget {
        @include lib-font-size($font-size__m);
        line-height: $line-height__l;

        .amfaq-title {
            @include lib-heading(h3);
        }

        .am {
            &-title {
                @include lib-heading(h3);
            }

            &-item {
                margin-bottom: 30px;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________
@include min-screen($screen__xxs) {
    .block {
        &.widget {
            &.block-viewed-products-grid {
                .products-grid {
                    .product-items {
                        li {
                            &.product-item {
                                .product-item-name,
                                .price-box {
                                    padding: 0 15px;
                                }
                                .product-item-actions {
                                    margin-bottom: 0;
                                }

                                .product-item-description {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .block.widget .products-grid .product-item,
    .page-layout-1column .block.widget .products-grid .product-item,
    .page-layout-3columns .block.widget .products-grid .product-item {
        width: 100%;
    }
}

@include max-screen($screen__s) {
    .block {
        &.block-viewed-products-grid,
        &.crosssell {
            .products-grid {
                .product-items {
                    li {
                        &.product-item {
                            margin: 0 0 20px !important;
                            box-shadow: 0 0 10px 0 $color-gray-light3;
                            border: 1px solid $color-gray-light7;
                            width: 100% !important;
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .sidebar {
        .block-reorder {
            .actions-toolbar {
                .primary {
                    display: inline-block;
                }

                .secondary {
                    text-align: left;
                }
            }
         }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .block.widget {
        .products-grid {
            .product-item {
                width: 100%;
                padding: 0;
            }
        }
    }
}

@include min-screen($screen__m) {
    .sidebar {
        .block {
            &.widget {
                .pager {
                    .item {
                        &:not(.pages-item-next) {
                            &:not(.pages-item-previous) {
                                @extend .abs-no-display-desktop;
                            }
                        }
                    }

                    .pages-item-next {
                        padding: 0;

                        .action {
                            margin: 0;
                        }
                    }
                }

                &.block-new-products {
                    .action-col {
                        float: right;
                        width: 200px;
                    }
                }
            }
        }
    }
    .block {
        &.block-viewed-products-grid {
            .product-items {
                li {
                    &.product-item {
                        box-shadow: none;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l + 1) {
    .block {
        &.block-viewed-products-grid,
        &.crosssell {
            .products-grid {
                .product-items {
                    li {
                        &.product-item {
                            width: calc(100% / 2 - 30px);
                            margin: 0 15px;

                            &:nth-child(2n + 1) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }

            .block-title {
                padding: 40px 15px 22px;
            }
        }
    }
}

@include min-screen($screen__l + 1) {
    .block {
        &.block-viewed-products-grid,
        &.crosssell {
            .products-grid {
                .product-items {
                    li {
                        &.product-item {
                            width: calc(100% / 4 - 23px);
                            margin: 0 15px;

                            &:last-child {
                                margin-right: 0;
                            }

                            &:nth-child(4n + 1) {
                                margin-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}
