.horizontal-usps {
    .usp-container {
        padding: 16px 0;

        .usp {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);

            &:before {
                width: 50px;
                height: 50px;
                display: inline-block;
                margin-right: 15px;
            }

            &.warehouse {
                &:before {
                    content: url(lib-icon-svg-url($icon-base-warehouse, $theme__primary__color));
                }
            }

            &.delivery {
                &:before {
                    content: url(lib-icon-svg-url($icon-base-delivery, $theme__primary__color));
                }
            }

            &.discount {
                &:before {
                    content: url(lib-icon-svg-url($icon-base-discount, $theme__primary__color));
                }
            }

            &.personal-advice {
                &:before {
                    content: url(lib-icon-svg-url($icon-base-personal, $theme__primary__color));
                }
            }
        }

        strong {
            display: block;
        }
    }
}

.product-list.usp-bar {
    border-bottom: 1px solid $color-gray-light8;

    ul {
        > li {
            @include lib-font-size($font-size__s);

            span,
            div {
                @include lib-vendor-prefix-display(flex);
                @include lib-font-size($font-size__base);
                @include lib-css(font-family, $font-family__base);
                font-weight: $font-weight__semibold;

                &:before {
                    content: url($icon-base-kuras-check-dark);
                    display: inline-block;
                    float: left;
                    margin-right: 17px;
                    position: relative;
                    height: 25px;
                }
            }
        }
    }
}

.usps {
    &-white {
        .usp-links {
            text-indent: -9999px;
            display: inline-block;
            width: 70px;
            height: 70px;
            margin-left: 2px;
            margin-right: 15px;
            float: right;

            &.mail-icon {
                @include svg-content-background($icon-base-mail-square, $color-gray1);
                background-color: $color-white;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-base-mail-square, $theme__secondary__color);
                    background-color: $color-gray1;
                }
            }

            &.phone-icon {
                @include svg-content-background($icon-base-phone-square, $color-gray1);
                background-color: $color-white;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-base-phone-square, $theme__secondary__color);
                    background-color: $color-gray1;
                }
            }

            &.whatsapp-icon {
                @include svg-content-background($icon-kuras-whatsapp-square, $color-white);
                background-color: $color-gray1;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-kuras-whatsapp-square, $color-gray1);
                    background-color: $theme__secondary__color;
                }
            }

            &.whatsapp-icon-yellow {
                @include svg-content-background($icon-kuras-whatsapp-square-yellow, $theme__secondary__color);
                background-color: $color-gray1;
                transition: all 0.2s ease-out;
            }

            &.chat-icon {
                font-size: 0;
                background-image: url('../images/chat-icon-white.svg');
                transition: all 0.2s ease-out;

                &:hover {
                    background-image: url('../images/chat-icon-hover.svg');
                }
            }
        }
    }

    &-yellow {
        .usp-links {
            &.mail-icon {
                @include svg-content-background($icon-base-mail-square, $color-gray1);
                background-color: $theme__secondary__color;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-base-mail-square, $theme__secondary__color);
                    background-color: $color-gray1;
                }
            }

            &.phone-icon {
                @include svg-content-background($icon-base-phone-square, $color-gray1);
                background-color: $theme__secondary__color;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-base-phone-square, $theme__secondary__color);
                    background-color: $color-gray1;
                }
            }

            &.whatsapp-icon {
                @include svg-content-background($icon-kuras-whatsapp-square, $theme__secondary__color);
                background-color: $color-gray1;
                transition: all 0.2s ease-out;

                &:hover {
                    @include svg-content-background($icon-kuras-whatsapp-square, $color-gray1);
                    background-color: $theme__secondary__color;
                }
            }

            &.chat-icon {
                font-size: 0;
                background-image: url('../images/chat-icon-white.svg');
                transition: all 0.2s ease-out;

                &:hover {
                    background-image: url('../images/chat-icon-hover.svg');
                }
            }
        }
    }
}

.footer-usps-wrapper {
    background-color: $color-white;
}


//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .horizontal-usps {
        @include lib-vendor-prefix-display(flex);

        .usp-container {
            @include lib-vendor-prefix-display(flex);
            width: 100%;
            justify-content: space-between;
            padding: 16px 0;
        }
    }
}