@use 'sass:math';

//
//  Variables
//  _____________________________________________

$braintree-input-border__color              : $color-gray76 !default;

$braintree-error__color                     : $color-red10 !default;
$braintree-focus__color                     : $color-blue2 !default;
$braintree-success__color                   : $color-dark-green1 !default;

$braintree-paypal-icon__height              : 16px !default;
$braintree-paypal-icon__width               : 16px !default;
$braintree-paypal-account__padding-right    : 50px !default;
$braintree-paypal-account__padding-vertical : 15px !default;

.payment-method-braintree {

    .hosted-date-wrap {
        @extend .abs-add-clearfix;
    }

    .hosted-control {
        @include lib-form-element-input($_type: input-text);
        position: relative;
        width: 225px;

        &.braintree-hosted-fields-focused {
            border-color: $braintree-focus__color;
        }

        &.braintree-hosted-fields-invalid:not(.braintree-hosted-fields-focused) {
            border-color: $braintree-error__color;

            & ~ .hosted-error {
                height: auto;
                opacity: 1;
            }
        }

        &.braintree-hosted-fields-valid {
            border-color: $braintree-success__color;
        }

        &.hosted-cid {
            width: 5rem;
        }

        &.hosted-date {
            $distance: 50px;

            float: left;
            margin-bottom: 7px;
            width: 6rem;

            &:first-of-type {
                margin-right: $distance;

                &:after {
                    content: '/';
                    display: inline;
                    padding-bottom: inherit;
                    padding-top: inherit;
                    position: absolute;
                    right: math.div(-$distance, 2);
                    top: 5px;
                }
            }
        }
    }

    .field-tooltip {
        right: 0;
    }

    .hosted-error {
        clear: both;
        color: $braintree-error__color;
        font-size: 1.2rem;
        height: 0;
        margin-top: 7px;
        opacity: 0;
    }
}

.braintree-paypal-logo {
    margin-top: $indent__s;
    text-align: center;
}

.braintree-paypal-account {
    padding: $braintree-paypal-account__padding-vertical 0 $braintree-paypal-account__padding-vertical $braintree-paypal-account__padding-right;
    position: relative;

    @include lib-icon-image(
        $_icon-image          : '../Magento_Braintree/images/paypal-small.png',
        $_icon-image-position : before,
        $_icon-image-width    : $braintree-paypal-icon__width,
        $_icon-image-height   : $braintree-paypal-icon__height
    );

    &:before {
        left: math.div($braintree-paypal-account--padding-right, 2) - math.div($braintree-paypal-icon--width, 2);
        margin-top: math.div(-$braintree-paypal-icon--height, 2);
        position: absolute;
        top: 50%;
    }

    .payment-method-type {
        font-weight: $font-weight__bold;
    }

    .payment-method-description {
        color: $color-gray34;
    }
}

.braintree-paypal-fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

.action-braintree-paypal-logo {
    @include lib-button-reset();

    img {
        margin: 0;
        width: 114px;
    }
}

.payment-method {
    .payment-method-content {
        .actions-toolbar {
            &.braintree-paypal-actions {
                margin-left: 0;
            }
        }
    }
}

//  My Account > Stored Payment Methods
.account {
    .table {
        .col {
            &.paypal-account {
                img {
                    vertical-align: middle;
                }

                + .actions {
                    vertical-align: middle;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .payment-method-braintree {
        .cvv {
            .field-tooltip {
                left: 6rem;
            }
        }
    }

    .braintree-paypal-account {
        border-color: $color-gray80;
        border-style: solid;
        border-width: 1px 0;
        display: inline-block;
        width: 50%;
    }

    //  My Account > Stored Payment Methods
    .account {
        .table-credit-cards {
            .col {
                &.actions {
                    width: 100px;
                }
            }
        }
    }
}
