.smile-es-range-slider {
    margin-top: 15px;

    .ui-slider {
        &-handle {
            border-radius: 0;
            background: $theme__primary__color;
            width: 16px;
            height: 16px;
            margin-top: -7px;
            margin-left: 0;

            &:before {
                display: none;
            }

            &:hover {
                background: $theme__primary__color;

                &:after {
                    display: none;
                }
            }
        }

        &-range {
            height: 2px;
            background: $catalog-background-color-dark;
            position: absolute;
        }

        &-horizontal {
            height: 2px;
            background: $catalog-border-color-lighten;
        }
    }

    a {
        &.ui-slider-handle {
            border-radius: 0;
            background: $theme__primary__color;
            width: 16px;
            height: 16px;
            margin-top: -7px;
            margin-left: 0;

            &:before {
                display: none;
            }

            &:hover {
                background: $theme__primary__color;

                &:after {
                    display: none;
                }
            }
        }
    }

    .text-center {
        margin-top: 15px;
    }

    .actions {
        &-toolbar {
            div:first-child {
                display: none;
            }
        }

        &-primary {
            .action {
                &.primary {
                    @include lib-button-checkout();
                    color: $theme__primary__color;
                    margin-top: 20px;
                    text-align: center;

                    &:before {
                        display: none;
                    }

                    &:hover {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.filter-options-item {
    .info-icon {
        width: 16px;
        background-image: url(lib-icon-svg-url($icon-base-exclamation-circle, $theme__primary__color));
        background-repeat: no-repeat;
        background-size: 16px;
        background-position: 0;
        height: 16px;
        display: inline-block;
        cursor: pointer;
        flex: 0 0 16px;
        margin-left: 10px;
        position: relative;

        .tooltip-popup {
            @include lib-css(font-weight, $font-weight__regular);
            font-size: $font-size__s;
            line-height: $line-height__s;
            position: absolute;
            background: $color-white;
            box-shadow: 0 0 8px $color-gray-light6;
            padding: 20px;
            z-index: 4;
            left: -15px;
            top: 36px;
            width: 100%;
            min-width: 275px;
            display: none;
        }

        &:hover {
            .tooltip-popup,
            .info-popup-arrow {
                display: block;
            }
        }

        .info-popup-arrow {
            background: url('../images/product-detail-info-arrow-alpha.png') no-repeat;
            width: 26px;
            height: 17px;
            z-index: 5;
            position: absolute;
            top: 22px;
            left: -5px;
            display: none;
        }
    }
}
