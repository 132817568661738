body {
  &.top-message-active {
    .product-info-main-wrapper.fixed {
      top: 109px;
      height: 100px;

      .page-main {
        height: 100px;
      }
    }

    .page-wrapper .product.info.detailed.fixed.with-addtocart {
      top: 209px;
    }

    .top-message {
      @include lib-font-size($font-size__s );
      position: fixed;
      top: 0;
      z-index: 9999;
      color: $theme__primary__color;
      text-align: center;
      width: 100%;
      background: $theme__secondary__color;
      padding: 5px;
      justify-content: center;
      min-height: 29px;

      a {
        color: $theme__primary__color;
        text-decoration: underline;
      }
    }

    .breadcrumbs {
      margin-top: 194px;
    }

    .page-header {
      margin-top: 29px;
    }
  }
}


@include screen($screen__m, $screen__l, $orientation: portrait) {
  body {
    &.top-message-active {
      .page-wrapper .product.info.detailed.fixed.with-addtocart {
        top: 209px;
      }

      &.account,
      &.catalog-category-view,
      &.catalog-product-view,
      &.customer-account-create,
      &.cms-index-index,
      &.customer-account-forgotpassword,
      &.cms-noroute-index,
      &.checkout-cart-index,
      &.checkout-onepage-success {
        #maincontent {
          &.page-main {
            margin-top: 125px;
          }
        }
      }
    }
  }
}

@include max-screen($screen__m) {
  body {
    &.top-message-active {
      .page-header {
        margin-top: 67px;
      }

      &.account,
      &.catalog-category-view,
      &.catalog-product-view,
      &.customer-account-create,
      &.cms-index-index,
      &.customer-account-forgotpassword,
      &.cms-noroute-index,
      &.checkout-cart-index,
      &.checkout-onepage-success {
        #maincontent {
          &.page-main {
            margin-top: 205px;
          }
        }
      }

      .catalog-category-view .sidebar .filter.active {
        padding: 186px 15px 0;
      }
    }
  }

  .top-message {
    height: 67px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}
