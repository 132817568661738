.blog-post-view,
.blog-index-index,
.blog-search-index {
    .page-title-wrapper {
        display: none;
    }
}

.blog-index-index,
.blog-search-index,
.blog-category-view {
    overflow-x: hidden;

    .post-list-wrapper {
        clear: both;
        padding: 0;
        margin-top: -21px;

        .post-list {
            margin: 21px -15px 0;

            .post-item-link {
                color: $primary__color;

                &:hover {
                    text-decoration: none;
                }

            }
        }

        .blog-item {
            position: relative;
            background-size: cover;
        }

        .blog-item,
        .blog-image {
            height: 212px;
        }

        .post-content {
            .post-text-hld {
                @include lib-css(font-family, $font-family__base);
                @include lib-css(font-size, $font-size__m);
                margin-top: 14px;
            }
        }

        .flex-container {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-prefix-flex-wrap(wrap);
            width: 100%;

            > [class*=col-] {
                display: flex;
                flex-direction: column;
            }

            .next-item {
                margin-bottom: 40px;

                .blog-item {
                    margin-bottom: 0;
                }

                .blog-title {
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(font-size, $font-size__l);
                    position: static;
                    margin-top: 15px;
                }
            }
        }
    }
}

.widget {
    &.block {
        &.blog-search {
            width: 292px;
            position: relative;
            right: 10px;
            float: right;
            display: inline-block;

            input {
                &.input-text {
                    background-color: $color-white;
                    border: 1px solid $color-gray-light3;
                    height: 45px;
                }
            }

            .action {
                &.search {
                    &:before {
                        @include lib-icon-svg($icon-base-search, $color-gray-light3);
                        width: 16px;
                        margin-top: 10px;
                        background: none;
                    }
                }
            }
        }
    }
}

.blog-post-view {
    @include lib-css(font-family, $font-family__base);

    .blog-sticky {
        .col-xs-12 {
            padding: 0;
        }

        .blog-item {
            .blog-image {
                height: 400px;
            }

            .blog-title {
                top: auto;
                position: absolute;
                bottom: 23px;

                max-width: 800px;
                margin: 0 auto;
                width: 800px;
                left: 16.66666667%;
                margin-left: 3px;

                a {
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(font-size, $font-size__xxl);
                    color: $color-white;
                }
            }
        }
    }

    .post-view {
        @include lib-css(font-weight, $font-weight__regular);
        @include lib-css(font-size, $font-size__m);
        margin-left: -10px;
        margin-right: -10px;

        p,
        td {
            @include lib-css(font-weight, $font-weight__regular);
            @include lib-css(font-size, $font-size__m);
            margin-bottom: 25px;
        }

        h3 {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(font-size, $font-size__xl);
        }

        .post-holder {
            padding: 0;
            background: transparent;

            &:hover {
                box-shadow: none;
            }
        }

        .post-nextprev-hld {
            .nextprev-link {
                color: $color-black;
            }
        }

        .item {
            &.post-categories {
                a {
                    color: $color-white;
                }
            }
        }
    }
}

.cms-page-view {
    .col-lg-4.col-md-4.col-sm-6.col-xs-12 {
        .post-holder {
            width: 100%;
        }
    }
}

.block {
    &.related {
        border-top: none;

        .block-title {
            margin-bottom: 30px;

            strong {
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(font-size, $font-size__xxl);
            }
        }

        .block-content {
            padding: 0;
            list-style: none;

            &.row {
                margin-left: -15px;
                margin-right: -15px;
            }

            .blog-image {
                a {
                    height: 0;
                    padding-bottom: 66%;
                }

                .post-item-image-link {
                    background-size: cover;
                    display: block;
                }

                .blog-label {
                    position: absolute;
                    top: 20px;
                    left: 15px;
                    padding: 4px 22px;
                    background: $theme__primary__color;
                    color: $color-white;

                    a {
                        @include lib-css(font-weight, $font-weight__bold);
                        @include lib-css(font-size, $font-size__base);
                        color: $color-white;
                    }
                }
            }

            .post {
                &-title {
                    margin-top: 15px;
                    margin-bottom: 15px;

                    a {
                        @include lib-css(font-weight, $font-weight__bold);
                        @include lib-css(font-size, $font-size__l);
                        color: $color-black;
                        text-decoration: none;
                    }
                }

                &-content {
                    @include lib-css(font-weight, $font-weight__light);
                    @include lib-css(font-size, $font-size__m);
                    color: $color-black;
                }

                &-item-link {
                    color: $color-black;
                }
            }
        }
    }
}

.blog-sticky {
    margin: 16px -14px 11px -23px;
    overflow: auto;

    .blog-title {
        a {
            &.post-item-link {
                text-decoration: none;
            }
        }
    }

    .first-item {
        padding-left: 20px;

        .blog-item {
            height: 454px;
            margin-bottom: 30px;

            .blog {
                &-image {
                    height: 100%;
                }

                &-title {
                    @include lib-css(font-family, $font-family__base);
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(font-size, $font-size__xxl);
                    bottom: 23px;
                    padding: 0 30px;
                    color: $color-gray56;
                }
            }
        }
    }

    .next-item {
        .blog-item {
            height: 211px;
            margin-bottom: 30px;

            .blog-title {
                @include lib-css(font-family, $font-family__base);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(font-size, $font-size__l);
                color: $color-gray82;
                bottom: 15px;
                padding: 0 20px;

            }
        }

        &:last-child {
            .blog-item {
                margin-bottom: 30px;
            }
        }
    }

    .blog-item {
        background-size: cover;
        position: relative;
        height: auto;
        margin-bottom: 43px;
        background-position: center center;

        .blog-image {
            height: 212px;
            background-size: cover;
            position: relative;
            background-position: center center;
        }

        .blog-title {
            @include lib-css(font-family, $font-family__base);
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(font-size, $font-size__l);
            margin-top: 20px;
            color: $color-white;

            h2 {
                margin-bottom: 6px;
            }

            a {
                color: $color-white;
            }
        }
    }

    .first-item,
    .next-item {
        .blog-image {
            background: -moz-linear-gradient(top, rgba(125, 185, 232, 0) 0%, rgba(62, 97, 111, 0.5) 99%, rgba(62, 97, 111, 0.51) 100%);
            background: -webkit-linear-gradient(top, rgba(125, 185, 232, 0) 0%, rgba(62, 97, 111, 0.5) 99%, rgba(62, 97, 111, 0.51) 100%);
            background: linear-gradient(to bottom, rgba(125, 185, 232, 0) 0%, rgba(62, 97, 111, 0.5) 99%, rgba(62, 97, 111, 0.51) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#007db9e8', endColorstr='#823e616f', GradientType=0);
        }

        .post-content {
            display: none;
        }

        .blog-title {
            @include lib-css(font-weight, $font-weight__regular);
            @include lib-css(font-size, $font-size__l);
            position: absolute;
            bottom: 20px;
            top: auto;
            padding: 0;
            color: $color-gray20;
        }
    }
}

.block {
    &.blog {
        &.block-categories {
            margin-left: 0;
            margin-bottom: 39px;
            display: inline-block;

            ul {
                margin: 0;

                li {
                    list-style: none;
                    display: inline-block;
                    margin: 0;

                    &.has-subs {
                        a {
                            &:after {
                                width: 24px;
                                height: 22px;
                                float: right;
                                margin-left: 2px;
                                margin-top: -1px;
                                @include lib-icon-svg($icon-base-caret-down, $color-gray20);
                            }
                        }

                        ul {
                            li {
                                a {
                                    &:after {
                                        content: '';
                                        display: none;
                                    }
                                }
                            }
                        }

                        &:hover {
                            a {
                                &:after {
                                    @include lib-icon-svg($icon-base-caret-up, $color-gray20);
                                }
                            }

                            ul {
                                display: block;

                                li {
                                    a {
                                        &:after {
                                            content: '';
                                        }
                                    }
                                }
                            }
                        }

                        &.active {
                            a {
                                @include lib-css(font-weight, $font-weight__bold);
                                @include lib-css(font-size, $font-size__m);
                                color: $theme__primary__color;
                            }

                            ul {
                                li {
                                    a {
                                        color: $color-gray20;

                                        &:hover {
                                            color: $theme__primary__color;
                                        }
                                    }

                                    &.active {
                                        a {
                                            color: $theme__primary__color;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    a {
                        @include lib-css(font-weight, $font-weight__regular);
                        @include lib-css(font-size, $font-size__m);
                        color: $color-gray20;
                        padding: 10px 0 7px 0;
                        margin-right: 24px;
                        display: inline-block;
                        border-bottom: 2px solid $color-white;
                    }

                    &.active {
                        a {
                            @include lib-css(font-weight, $font-weight__semibold);
                            @include lib-css(font-size, $font-size__m);
                            text-decoration: none;
                            color: $theme__primary__color;
                            border-bottom: 2px solid $theme__primary__color;
                            text-transform: capitalize;
                        }
                    }


                    &:hover {
                        a {
                            text-decoration: none;
                            color: $theme__primary__color;
                        }
                    }

                    ul {
                        display: none;
                        float: left;
                        position: absolute;
                        width: 338px;
                        z-index: 1;
                        margin: 0;
                        padding: 15px;

                        li {
                            width: 100%;

                            a {
                                padding: 0 0;
                                margin-left: 0;
                            }

                            &:hover a {
                                color: $theme-_primary__color;
                            }
                        }
                    }
                }
            }
        }
    }
}

@include min-screen($screen__m) {
    .blog {
        &.block-categories {
            width: 63%;
        }
    }

    .widget {
        &.block {
            &.blog-search {
                width: 37%;
                right: 0;
            }
        }
    }
}

@include min-screen($screen__l) {
    .blog {
        &.block-categories {
            width: 65%;
        }
    }

    .widget {
        &.block {
            &.blog-search {
                width: 30%;
                right: 0;
            }
        }
    }
}

@include min-screen($screen__xl) {
    .blog {
        &.block-categories {
            width: 70%;
        }
    }
}

@include max-screen($screen__m) {
    .blog-index-index,
    .blog-search-index,
    .blog-category-view,
    .blog-post-view {
        .columns {
            padding-top: 0;

            .column {
                &.main {
                    @include lib-vendor-prefix-display(flex);
                    @include lib-vendor-prefix-flex-direction(column)
                }
            }
        }
    }

    .nav-and-search {
        order: 0;
    }

    .blog {
        &.block-categories {
            width: 50%;
        }
    }

    .blog-sticky {
        order: 1;
        margin: 0 -15px;

        .first-item,
        .next-item {
            padding: 0 15px;
        }
    }

    .post-list-wrapper {
        order: 2;

        .post-list {
            .next-item {
                padding-left: 15px;

                .blog {
                    &-item {
                        height: 180px;
                    }

                    &-image {
                        height: 180px;
                    }

                    &-title {
                        @include lib-css(font-weight, $font-weight__semibold);
                        @include lib-css(font-size, $font-size__l);
                        padding: 0;
                    }
                }
            }
        }
    }

    .blog-navigation {
        .block {
            &.blog {
                &.block-categories {
                    width: 100%;
                    max-width: 100%;
                    margin-bottom: 10px;

                    .accordion {
                        overflow: hidden;
                        border: 1px solid $color-gray84;
                        height: 45px;
                        position: relative;

                        &:after {
                            position: absolute;
                            left: 0;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            background: transparent;
                            display: block;
                            content: '';
                        }

                        &:before {
                            @include lib-icon-svg($icon-base-arrow-down, $color-black);
                            position: absolute;
                            top: 10px;
                            right: 20px;
                            display: inline-block;
                            width: 12px;
                            background: transparent;
                        }

                        li {
                            display: none;
                            padding-left: 15px;

                            a {
                                @include lib-css(font-weight, $font-weight__regular);
                                @include lib-css(font-size, $font-size__base);
                            }
                        }

                        li {
                            &.active {
                                display: block;

                                a {
                                    @include lib-css(font-weight, $font-weight__regular);
                                    @include lib-css(font-size, $font-size__base);
                                    color: $color-gray20;
                                    border: 0;
                                }
                            }
                        }

                        &.open {
                            overflow: visible;
                            height: auto;

                            &:after {
                                height: 42px;
                                width: 50%;
                                left: auto;
                            }

                            &:before {
                                @include lib-icon-svg($icon-base-arrow-up, $color-black);
                                background: transparent;
                            }

                            li {
                                display: block;

                                &.active {
                                    a {
                                        @include lib-css(font-weight, $font-weight__regular);
                                        @include lib-css(font-size, $font-size__base);
                                        color: $theme__primary__color;
                                        border: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .widget {
            &.block {
                &.blog-search {
                    width: 100%;
                    right: 0;
                    margin-top: 10px;
                }
            }
        }
    }

    .blog-post-view {
        .columns {
            .column {
                &.main {
                    flex-direction: column-reverse;
                    margin-top: 135px;
                }
            }
        }

        .blog-sticky {
            .blog-item {
                .blog-title {
                    left: 0;
                    max-width: 100%;
                    width: 100%;
                    margin: 0;
                    padding-left: 15px;
                }
            }
        }

        .post-gallery {
            .gallery-image {
                width: 25vw;
                height: 25vw;
            }
        }
    }

}

.blog-item {
    .blog-image {
        .blog-label {
            @include lib-css(font-family, $font-family__base);
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(font-size, $font-size__base);
            text-transform: lowercase;
            position: absolute;
            top: 20px;
            left: 0;
            padding: 5px 22px;
            background: $theme__primary__color;
            color: $color-white;

            a {
                color: $color-white;
            }
        }
    }
}
