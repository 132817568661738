$border_color_grey: #a3a3a3;
$table_head_background-color: #e5e5e5;

.tier-price-block {
    margin-top: 12px;
    padding-top: 20px;
    border-top: 1px solid $border_color_grey;
}

.tier-price-block {
    padding-top: 10px;

    small {
        font-size: 14px;
        margin-bottom: 10px;
        display: block;
    }

    .table-bordered {
        font-size: 13px;

        .price-container {
            .price {
                font-size: 13px;
            }
        }
    }

    thead {
        tr {
            background: $table_head_background-color;
        }
    }
}

.min-unit-tier-price-incl,
.min-unit-tier-price-excl,
.price-wrapper {
    &:before {
        content: '€';
        margin-right: 3px;
    }
}
