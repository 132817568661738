.page-product-bundle {
  .fancybox-wrap {
    &.fancybox-opened {
      width: 80% !important; //override ko
      left: 10% !important; //override ko

      .fancybox-skin {
        background-color: $color-white;
        border-radius: 0;
      }

      .fancybox-item {
        &.fancybox-close {
          top: 29px;
          right: 10px;
          background: none;

          &:after {
            @include lib-icon-svg($icon-base-close, $color-black);
            display: inline-block;
            width: 14px;
            height: 14px;
          }
        }
      }

      .fancybox-inner {
        width: 100% !important;

        .product-options-bottom {
          display: none;
        }
      }
    }
  }

  .option-image {
    background-size: contain;
    background-repeat: no-repeat;
    height: 150px;
  }

  .product-info-main-wrapper {
    margin-bottom: 28px;
  }

  .product-options-wrapper {
    @include lib-css(font-family, $font-family__base);

    .legend {
      &.title {
        @include lib-font-size($font-size__xxl);
        font-weight: $font-weight__semibold;
        border-bottom: none;
      }
    }

    .fieldset-bundle-options {
      margin-bottom: 30px;
      padding-bottom: 30px;
      border-bottom: 1px solid $color-gray-light3;

      label {
        &.option-title {
          @include lib-font-size($font-size__xl);
          font-weight: $font-weight__semibold;
          padding: 0;
          color: $theme__primary__color;
          margin-bottom: 10px;
          display: block;
        }
      }

      .option-wrapper {
        select {
          width: auto
        }
      }

      .nested {
        .qty {
          .label {
            @include lib-font-size($font-size__base);
            font-weight: $font-weight__regular;
            margin:15px 0 5px 0;
            display: block;
          }
        }
      }

       .field {
        &.option {
          margin: 0 0 35px;
        }
      }
    }

    p {
      &.required {
        display: none;
      }
    }

    .box-tocart {
      margin-top: 0;

      .fieldset {
        .field {
          &.qty {
            width: 100px;
          }
        }
      }
    }
  }

  .bundle-info {
    .box-tocart {
      .actions {
        width: auto;

        .action {
          &.primary {
            &.tocart {
              @include lib-button-checkout();
              text-transform: uppercase;
              padding: 12px 95px;

              span {
                background: url(lib-icon-svg-url($icon-base-cart, $color-white)) no-repeat center right;
                padding-right: 34px;
              }
            }
          }
        }
      }
    }
  }

  .total-amount {
    @include lib-font-size($font-size__m);
    font-weight: $font-weight__semibold;
  }
  .stock {
    &.available {
      @include lib-font-size($font-size__base);
      font-weight: $font-weight__regular;
      line-height: $line-height__s;
      color: $color-gray20;
      text-transform: none;

      &:before {
        width: 16px;
        margin-right: 11px;
        line-height: 25px;
      }
    }
  }

  .product-usps {
    margin-top: 32px;
  }

  .bundle-actions {
    a {
      &.action {
        &.primary {
          &.customize {
            @include lib-button-checkout();
            text-transform: uppercase;

            span {
              background: url(lib-icon-svg-url($icon-base-cart, $color-white)) no-repeat center right;
              padding-right: 34px;
            }
          }
        }
      }
    }
  }
}

.block-bundle-summary {
  @include lib-css(font-family, $font-family__base);

  .title,
  .bundle-summary,
  .available.stock,
  .product-addto-links,
  .field.qty {
    display: none;
  }

  .content {
    .marketingrule,
    .product-image-container {
      display: none;
    }
  }

  .total-configured-price-wrapper {
    @include lib-font-size($font-size__m);
    font-weight: $font-weight__semibold;
    line-height: $line-height__s;
  }

  .price-wrapper {
    .price {
      @include lib-font-size($font-size__xxl);
      font-weight: $font-weight__semibold;
      line-height: $line-height__s;
    }
  }
}

.bundle-options-container {
  display: none;
}

.page-product-bundle {
  .fixed-column-cart {
    margin: 28px 0;
  }
}