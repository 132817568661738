//
//  Variables
//  _____________________________________________

$cart-summary-color                 : $color-gray82;
$cart-summary-border-color          : $theme__primary__border__color;
$cart-summary-background-color      : $color-white;
$cart-summary-remove-item-color     : $color-gray46;
$cart-quality-background-color      : $color-gray-light0;
$cart-quality-color                 : $color-white;

//
//  Shopping cart
//  ---------------------------------------------

.checkout-cart-index {
    h1 {
        @include lib-font-size($font-size__xxxl);
        @include lib-css(font-weight, $font-weight__bold);
        margin-bottom: 23px;
    }

    .footer {
        &.newsletter,
        .footer-usps-wrapper,
        .footer-menu-wrapper {
            display: none;
        }
    }

    @include lib-css(font-family, $font-family__base);

    .page-title-wrapper {
        margin: 30px 0 5px;

        h1 {
            &.page-title {
                @include lib-font-size($font-size__xxl);
                @include lib-css(font-weight, $font-weight__bold);
                color: $color-gray20;
            }
        }
    }

    .page-footer {
        margin-top: 0;
    }

    .cart-container {
        .cart.table-wrapper.detailed {
            .price-excluding-tax {
                display: none;
            }
        }
    }
}

.checkout-cart-index,
.offerte-request-form {
    .usp-cart {
        display: block;
        width: 100%;
        float: left;
        max-width: 450px;
        clear: left;

        .usp-title {
            @include lib-css(font-family, $font-family__base);
            @include lib-css(font-weight, $font-weight__semibold);
            @include lib-font-size($font-size__xl);
            line-height: $line-height__xs;
        }

        .content {
            ul {
                @include lib-list-reset-styles();
                padding: 15px 0 25px;

                li {
                    @include lib-css(font-family, $font-family__base);
                    @include lib-font-size($font-size__base);
                    height: auto;
                    padding-left: 30px;
                    margin-bottom: 8px;
                    line-height: $line-height__s;
                    font-weight: $font-weight__regular;
                    background-image: url(lib-icon-svg-url($icon-base-usp, $theme__secondary__color));
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                }
            }
        }
    }

    .continue-shopping {
        float: left;
        margin-bottom: 30px;
        width: 100%;
        max-width: 450px;
    }
}

//  Summary block
.cart-container {
    .cart-summary {
        @extend .abs-add-box-sizing;
        @extend .abs-adjustment-incl-excl-tax;
        @include lib-css(background, $cart-summary-background-color);
        @include lib-css(border, 1px solid $cart-summary-border-color);
        margin-bottom: $indent__m;
        padding: 11px 20px $indent__m;

        > .title {
            @include lib-font-size($font-size__xxl);
            @include lib-css(border-bottom, 1px solid $cart-summary-border-color);
            display: block;
            padding-bottom: 13px;
        }

        .block {
            margin-top: 16px;
            margin-bottom: 0;

            &.shipping {
                .title {
                    position: relative;
                    margin-bottom: 15px;

                    &:after {
                        @include lib-icon-svg($icon-base-arrow-down, $color-gray20);
                        position: absolute;
                        right: 0;
                        top: 0;
                        width: 18px;
                        height: 16px;
                    }

                    strong {
                        @include lib-font-size($font-size__m);
                        @include lib-css(font-weight, $font-weight__regular);
                    }
                }

                .estimate,
                .shipping-summary-title {
                    display: none;
                }

                &.active {
                    .title {
                        &:after {
                            @include lib-icon-svg($icon-base-arrow-up, $color-gray20);
                        }
                    }
                }
            }

            .item-options {
                margin-left: 0;
            }

            .fieldset {
                .field {
                    margin: 0 0 $indent__s;

                    &.note {
                        @include lib-font-size($font-size__s);
                    }

                    .label {
                        display: inline;
                    }
                }
            }
        }

        .actions-toolbar {
            > .primary {
                button {
                    @include lib-button-primary();
                }
            }
        }

        .totals-tax-summary {
            th,
            td {
                cursor: text;
            }

            .price {
                padding-right: 0;

                &:after {
                    display: none;
                }
            }
        }

        .totals-tax-details {
            &.shown {
                display: none;
            }
        }
    }

    .form-cart {
        .cart {
            &.main {
                &.actions {
                    &.row {
                        float: right;
                    }
                }
            }
        }

        .action {
            &.continue,
            &.clear {
                display: none;
            }

            &.update {
                @include lib-button-secondary();
            }
        }
    }

    .cart-discount {
        display: block;
        width: 100%;
        float: left;

        .block {
            &.discount {
                .title {
                    strong {
                        @include lib-font-size($font-size__m);
                        font-weight: $font-weight__regular;
                        color: $theme__primary__color;
                        cursor: pointer;
                    }
                }

                .content {
                    .label {
                        display: none;
                    }

                    .control {
                        width: auto;
                    }

                    .actions-toolbar {
                        display: inline-block;
                        margin: 0;
                    }

                    .field {
                        margin-bottom: 0;
                        display: inline-block;
                    }

                    div.mage-error {
                        position: absolute;
                    }
                }

                .action {
                    &.primary {
                        padding: 6px 15px;
                        font-weight: $font-weight__regular;
                        height: 45px;
                    }
                }
            }
        }
    }
}

.qualitymarks {
    &-cart {
        background: $cart-quality-background-color;
        margin-top: 35px;
    }
    &-title {
        @include lib-font-size($font-size__xl);
        background: $theme__primary__color;
        color: $cart-quality-color;
        font-weight: $font-weight__bold;
        padding: 11px 34px;
        display: inline-block;
    }

    &-image-container {
        @include lib-vendor-prefix-display();
        text-align: center;
        justify-content: space-evenly;
        padding: 33px 0;

        img {
            align-self: center;
            flex: 0 0 auto;
            margin: 0 auto;
        }
    }
}


.checkout-helper-container {
    @include lib-font-size($font-size__xl);
    color: $theme__primary__color;
    font-weight: $font-weight__bold;
    text-align: center;
    margin-top: 80px;
}

//  Totals block
.cart-totals {
    @extend .abs-sidebar-totals;
    @include lib-css(border-top, 1px solid $cart-summary-border-color);
    padding-top: $indent__s;

    .table-wrapper {
        margin-bottom: 0;
        overflow: inherit;

        .mark {
            background: transparent;
        }

        .mark,
        .amount {
            @include lib-font-size($font-size__m);
            border: 0;
            padding: 6px 0;

            .label {
                padding: 0;
            }
        }

        .totals {
            .shipping {
                .mark,
                .amount {
                    padding-bottom: 15px;
                }
            }

            .grand {
                .mark,
                .amount {
                    padding-top: 15px;
                }
            }
        }
    }
}

//  Products table
.cart.table-wrapper {
    .items {
        thead {
            & + .item {
                border-top: $border-width__base solid $cart-summary-border-color;
            }
        }

        > .item {
            border-bottom: $border-width__base solid $cart-summary-border-color;
            position: relative;
        }
    }

    .item {
        &-actions {
            td {
                padding-bottom: $indent__s;
                text-align: center;
                white-space: normal;
            }
        }
    }

    .actions-toolbar {
        @extend .abs-add-clearfix;
        margin-top: 13px;

        > .action {
            margin-bottom: $indent__s;
            margin-right: $indent__s;
            color: $cart-summary-remove-item-color;

            &:last-child {
                margin-right: 0;
            }

            &.action-edit {
                display: none;
            }
        }
    }

    .action {
        &.help {
            &.map {
                @extend .abs-action-button-as-link;
                font-weight: $font-weight__regular;
            }
        }

        .configure {
            display: inline-block;
            margin: 0 0 $indent__base;
        }

        &-delete {
            text-decoration: underline;
        }
    }

    .product {
        &-item-details {
            white-space: normal;
        }

        &-item-name {
            display: inline-block;

            a {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__bold);
                color: $color-gray20;
            }

            .stock {
                display: none;
            }

            & + .item-options {
                margin-top: $indent__s;
            }
        }

        &-image-wrapper {
            @extend .abs-reset-image-wrapper;
        }

        &-item-price {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(baseline);
            justify-content: flex-end;

            .minicart-old-price {
                @include lib-font-size($font-size__m);
                line-height: $line-height__xs;
                text-decoration: line-through;
                margin-right: 10px;
            }
        }
    }

    .gift-registry-name-label {
        &:after {
            content: ':';
        }
    }

    //  Product options
    .item-options {
        @extend .abs-product-options-list;
        @extend .abs-add-clearfix;
        @include lib-font-size($font-size__s);
        margin-bottom: $indent__s;
    }

    .price-label {
        display: none;
    }

    .product-info-main {
        width: 100%;

        .price-box {
            margin: 0;

            .special-price {
                @include lib-vendor-prefix-order(1);
                float: right;
                margin: 0;

                .price {
                    @include lib-font-size($font-size__m);
                    font-weight: $font-weight__regular;
                }
            }

            .old-price {
                margin-right: 5px;
                float: right;

                .price {
                    @include lib-font-size($font-size__xxl);
                    font-weight: $font-weight__regular;
                }
            }
        }
    }
}

.cart-container {
    .form-cart {
        @extend .abs-shopping-cart-items;
    }

    .checkout-methods-items {
        @extend .abs-reset-list;
        margin-top: $indent__base;
        margin-bottom: $indent__base;
        text-align: center;

        .action {
            &.primary {
                @include lib-button-checkout();
                color: $theme__primary__color;
                width: 100%;
            }
        }

        .item {
            & + .item {
                display: none;
            }
        }
    }

    .qty-col {
        padding: 0;
    }

    .qty-plus,
    .qty-minus {
        width: 33.33%;
        height: 43px;
        float: left;
        cursor: pointer;
        text-align: center;

        div {
            width: 12px;
            height: 43px;
            display: inline-block;
        }
    }

    .qty-plus {
        border-left: 1px solid $theme__primary__border__color;

        div {
            background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray64)) no-repeat center center;
        }

        &:hover {
            div {
                background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
            }
        }
    }

    .qty-minus {
        border-right: 1px solid $theme__primary__border__color;

        div {
            background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray64)) no-repeat center center;
        }

        &:hover {
            div {
                background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
            }
        }
    }

    .control {
        &.qty {
            width: 100%;
            margin: 0;
            height: 45px;
            border: 1px solid $theme__primary__border__color;

            .input-text {
                &.qty {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__semibold);
                    width: 33.33%;
                    height: 43px;
                    float: left;
                    border: 0;
                    vertical-align: top;
                    margin: 0;
                    padding: 0;
                    color: $catalog-text-color;
                    text-align: center;
                }
            }
        }
    }

    .main {
        &.action {
            display: none;
        }
    }
}

//  Products pager
.cart-products-toolbar {
    .toolbar-amount {
        margin: $indent__m 0 15px;
        padding: 0;
        text-align: center;
    }

    .pages {
        margin: 0 0 $indent__m;
        text-align: center;

        .items {
            > .item {
                border-bottom: 0;
            }
        }
    }

    &-top {
        border-bottom: $border-width__base solid $border-color__base;
    }
}

//
//  Cross sell
//  ---------------------------------------------

.block {
    &.crosssell {
        margin-top: 70px;
        width: 100%;

        .block-title {
            border-bottom: 1px solid $color-gray64;
            padding-bottom: 22px;
            margin-bottom: 37px;
            margin-top: 40px;

            strong {
                @include lib-css(font-family, $font-family__base);
                @include lib-font-size($font-size__xl);
                @include lib-css(font-weight, $font-weight__bold);
            }
        }

        .product {
            &.name {
                a {
                    @include lib-css(font-weight, $font-weight__semibold);
                    @include lib-font-size($font-size__m);
                    line-height: $line-height__s;
                }
            }

            &-items {
                margin-left: 0;

                .product-item {
                    border: 1px solid $theme__primary__border__color;
                    margin: 0;

                    &-info {
                        width: 100%;
                    }

                    .product-item-actions {
                        display: block;
                        margin: 0;
                        box-shadow: none;
                        border: none;
                        width: 76px;
                    }

                    &:hover {
                        .product-item-info {
                            box-shadow: 0 0 10px 0 $color-gray-light3;
                        }
                    }
                }
            }
        }
    }
}

.cart-tax-info,
.cart {
    &.cart-tax-info {
        + .cart-tax-total {
            display: block;
        }
    }
}

.checkout-index-index {
    .columns {
        position: relative;
    }

    .checkout-helper-container {
        position: absolute;
        bottom: 10px;
        width: 100%;
        text-align: center;
    }

    .checkout-login {
        margin: 160px auto 100px;
        max-width: 396px;
        padding: 30px;
        border: 1px solid $color-gray78;
        background-color: $color-white;

        .control {
            input {
                height: 40px;
            }
        }

        .fieldset {
            margin-bottom: 20px;

            .field {
                .control {
                    width: 100%;
                }

                .label {
                    @include lib-font-size($font-size__base);
                    @include lib-css(font-weight, $font-weight__regular);
                    text-align: left;
                    display: block;
                    width: 100%;
                    margin-bottom: 5px;

                    &:after {
                        display: none;
                    }
                }
            }
        }

        .block-title {
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__regular);
            border-bottom: 0;
            padding-bottom: 10px;
        }

        .field {
            &.note {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
                color: $theme__primary__color;
            }
        }

        form {
            .actions {
                &-toolbar {
                    padding-top: 20px;
                    margin-left: 0;

                    .secondary {
                        float: left;
                    }
                }

                &-secondary {
                    padding-top: 25px;
                    border-top: 1px solid $color-gray78;

                    .button-primary {
                        @include lib-button-secondary();
                        text-transform: uppercase;
                        width: 100%;
                    }
                }
            }
        }

        .secondary {
            margin-top: 10px;

            a {
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                color: $theme__primary__color;
                text-decoration: underline;

                i {
                    margin-right: 5px;
                    font-size: 11px;
                    float: left;
                    margin-top: 2px;
                }
            }
        }

        .primary {
            width: 100%;

            .button {
                &.login {
                    &.primary {
                        @include lib-button-primary();
                        text-transform: uppercase;
                    }
                }
            }
        }

        .login-message-registration {
            margin-top: 10px;
            color: $color-gray61;
        }
    }
}

.checkout-onepage-success {
    .page-title-wrapper {
        margin-top: 30px;
    }

    .checkout-success {
        .actions-toolbar {
            .primary {
                .button-primary {
                    @include lib-button-primary();
                    text-decoration: none;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .cart {
        &.table-wrapper {
            overflow: inherit;

            .row-item {
                padding: 10px 0;
                border-bottom: 1px solid $cart-summary-border-color;
            }

            .product {
                &-item-price {
                    margin-top: 10px;
                }

                &-item-name {
                    padding-bottom: 10px;
                }
            }
        }

        &-container {
            .form-cart {
                @extend .abs-shopping-cart-items-mobile;
            }
        }
    }

    .checkout-cart-index,
    .checkout-index-index {
        .col-search {
            display: none;
        }

        #maincontent {
            &.page-main {
                margin-top: 113px;

                .cart-container {
                    display: flex;
                    flex-direction: column;
                }

                .cart-summary {
                    order: 1;
                }
            }
        }

        .checkout-login {
            margin: 0 auto 100px;
        }
    }

    .block {
        &.crosssell {
            width: 100%;
        }
    }

    .checkout-index-index {

    }

    .qualitymarks {
        &-cart {
            @include lib-vendor-prefix-display(inline-block);
            margin-top: 5px;
            width: 100%;
        }

        &-image-container {
            @include lib-vendor-prefix-flex-wrap(wrap);

            img {
                margin: 10px;
            }
        }
    }

    .discount {
        &.block {
            .fieldset {
                &.coupon {
                    display :flex;
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .cart-container {
        @extend .abs-add-clearfix-desktop;

        .form-cart {
            @extend .abs-shopping-cart-items-desktop;
            width: 66%;
        }

        .widget {
            float: left;
        }
    }

    .cart-summary {
        float: right;
        position: relative;
        width: 32%;

        .actions-toolbar {
            .column {
                &.main & {
                    @extend .abs-reset-left-margin-desktop;

                    > .secondary {
                        float: none;
                    }
                }
            }
        }

        .block {
            .fieldset {
                .field {
                    @include lib-form-field-type-revert($_type: block);
                    margin: 0 0 $indent__s;
                }
            }
        }
    }

    .cart {
        &.table-wrapper {
            .items {
                min-width: 100%;
                width: auto;
            }

            .row-item {
                padding-bottom: 10px;
                border-bottom: 1px solid $cart-summary-border-color;
                margin: 0 0 10px 0;
            }

            .item {
                &-actions {
                    td {
                        text-align: right;
                    }

                    .actions-toolbar {
                        @extend .abs-reset-left-margin-desktop;
                        text-align: left;
                    }
                }
            }

            .product {
                &-item-details {
                    display: table-cell;
                    vertical-align: top;
                    white-space: normal;
                    width: 99%;
                }
            }
        }

        .action {
            margin-bottom: $indent__s;
        }
    }

    //  Products pager
    .cart-products-toolbar {
        .toolbar-amount {
            line-height: 30px;
            margin: 0;
        }

        .pages {
            float: right;
            margin: 0 0 1px;

            .item {
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .cart {
        .table-wrapper {
            .cart {
                + .cart-products-toolbar {
                    margin-top: $indent__m;
                }
            }
        }
    }
}
