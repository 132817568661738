.ves-container {

  .row-inner {
    &.clearfix {
      width: 100%;
    }
  }

  .block-content {
    h1 {
      margin: 20px 0 28px 0;
      font-family:  $heading__font-family__base;
      font-weight: $h1__font-weight;
      font-size: $h1__font-size;
    }

    h2 {
      font-family:  $h2__font-family;
      font-weight: $h2__font-weight;
      font-size: $h2__font-size;
      margin: 23px 0 3px 0;
    }

    ul {
      list-style-type: disc;
      margin-left: 12px;

      li {
        font-family:  $font-family__base;
        padding-left: 20px;
      }
    }

    table.table-style {
      margin: 40px 0;
      border: none;
      font-family:  $font-family__base;

      tr {
        height: 50px;

        &:first-child {
          background: $theme__primary__color;
          font-weight: $font-weight__heavier;
          color: $color-white;
          font-size: $font-size__m;
        }

        &:not(:first-child){
          td {
            &:first-child {
              font-weight: $font-weight__bold;
            }
          }
        }

        &:nth-child(odd):not(:first-child){
          background: $color-gray-light0;
        }

        td {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    table.table-style-small {
      width: auto;

      td {
        padding: 1px 5px;
      }
    }
  }
}

.flex-text-image-full-height {
  margin-bottom: 30px;
  background: $color-white;

  .row-level-1 {
    > .row-inner {
      display: flex;

      .col-inner {
        height: 100%;
        background-size: cover;

        .widget-html {
          margin-bottom: 0;

          .block-content {
            padding: 30px 20px;

            h2, h3 {
              margin-top: 0;
              margin-bottom: 20px;
            }

            .action {
              margin-top: 15px;
            }
          }
        }
      }
    }
  }
}