.menu-overlay {
    background-color: transparent;
    position: fixed;
    transition: background-color .5s ease;

    &.active {
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background-color: $theme__transparant-color-dark-darker;
    }
}

.category-menu-wrapper {
    display: none;
    position: absolute;
    top: 80px;

    &.active {
        width: 100%;
        display: block;
    }
}

.col-menu {
    .category-button-container {
        color: $color-white;
        margin: 0;
        padding: 11px 0 5px 15px;
        height: 65px;

        @include min-screen($screen__m + 1) {
            &:hover {
                cursor: pointer;
                background: $color-white;
                color: $theme__primary__color;

                .category-button-down {
                    display: inline-block;
                    background-image: url(lib-icon-svg-url($icon-base-arrow-down, $theme__primary__color));
                    background-size: 10px 10px;
                    width: 10px;
                    height: 10px;
                    margin-left: 7px;
                }
            }
        }

        &.open {
            background: $color-white;
            color: $theme__primary__color;

            &:after {
                @include lib-icon-svg($icon-base-close, $color-white);
                width: 32px;
                height: 32px;
                display: none;
            }

            .category-button-down {
                display: inline-block;
                background-image: url(lib-icon-svg-url($icon-base-arrow-up, $theme__primary__color));
                background-size: 10px 10px;
                width: 10px;
                height: 10px;
                margin-left: 7px;
            }
        }

        &:after {
            @include lib-icon-svg($icon-base-menu, $color-white);
            height: 32px;
            width: 32px;
            display: none;

            &:hover {
                color: $color-white;
                background: transparent;
            }
        }

        .category-button {
            @include lib-font-size($font-size__base);
            @include lib-css(font-family, $font-family__base);
            line-height: $line-height__xs;
            position: relative;
            top: 0;

            &-title {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__semibold);
                text-transform: capitalize;
                display: block;
            }

            &-down {
                display: inline-block;
                background-image: url(lib-icon-svg-url($icon-base-arrow-down, $color-white));
                background-size: 10px 10px;
                width: 10px;
                height: 10px;
                margin-left: 7px;
            }
        }
    }
}

.top-bar-menu {
    .ves-megamenu {
        background: transparent;
        max-height: 40px;

        .level0 {
            a {
                @include lib-css(font-weight, $font-weight__regular);
                line-height: 40px;
                padding: 0 15px;
                color: $color-white;
                text-transform: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

header {
    .main-menu {
        .navigation {
            background: inherit;

            .ves-megamenu {
                position: relative;
                left: 0;
                background: transparent;
                list-style: none;
                padding: 0;
                margin: 0;
                float: left;
                width: 100%;

                li {
                    a {
                        @include lib-font-size($font-size__m);
                        @include lib-css(font-weight, $font-weight__bold);
                        @include lib-css(font-family, $font-family__base);
                    }

                    &.level0 {
                        @include lib-vendor-prefix-display(flex);
                        width: auto;
                        float: none;
                        margin: 0;

                        & > a {
                            @include make-col(4);
                            left: 5px;
                            text-transform: none;

                            & > span {
                                color: $color-black;
                                padding-left: 30px;
                                position: relative;
                                z-index: 2;
                                background: $color-white;
                                display: inline-block;
                                width: 100%;

                                @include min-screen($screen__m + 1) {
                                    &:hover {
                                        color: $theme__primary__color;
                                    }
                                }

                                &.caret,
                                &.opener {
                                    display: none;

                                    &:before {
                                        display: inline-block;
                                        @include lib-icon-svg($icon-base-arrow-down, $color-gray20);
                                        width: 11px;
                                        height: 11px;
                                        margin-top: 5px;
                                    }
                                }
                            }
                        }

                        @include min-screen($screen__m + 1) {
                            &:hover {
                                &:after {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    width: calc(66.66% + 15px);
                                    right: 0;
                                    top: 0;
                                    background: $color-gray96;
                                    height: 100%;
                                }

                                &:before {
                                    content: '';
                                    display: block;
                                    position: absolute;
                                    width: calc(100% - 25px);
                                    top: 0;
                                    background-color: $color-white;
                                    height: 100%;
                                    left: 25px;
                                }

                                > a {
                                    background: transparent;
                                    float: none;

                                    span {
                                        background: $color-white;
                                    }
                                }
                            }
                        }
                        @include min-screen($screen__m + 1) {
                            &.no-dropdown {
                                &:hover {
                                    &:after {
                                        display: none;
                                    }
                                }
                            }
                        }

                        @include min-screen($screen__m + 1) {
                            &:hover {
                                > a {
                                    background: transparent;

                                    span {
                                        color: $theme__primary__color !important; //overrule ves plugin
                                        background-color: $color-gray96;
                                    }
                                }
                            }
                        }

                        &.active {
                            > a {
                                background: transparent;
                                border: 0;

                                span {
                                    color: $theme__primary__color !important; //overrule ves plugin
                                    background: transparent;
                                    margin: 0;
                                }
                            }
                        }

                        &:first-child {
                            > a {
                                &:before {
                                    display: block;
                                    height: 19px;
                                    width: 100%;
                                    content: '';
                                    background: $color-white;
                                }
                            }
                        }

                        .submenu {
                            .submenu {
                                background-color: $color-gray96;
                            }

                            .active {
                                > a {
                                    border-color: transparent;
                                }
                            }
                        }
                    }
                }

                @include min-screen($screen__m + 1) {
                    &.menu-hover {
                        .subhover {
                            &:hover {
                                .dropdown-menu {
                                    @include lib-vendor-prefix-display(flex);
                                    @include lib-vendor-prefix-flex-wrap(wrap);
                                    @include lib-vendor-prefix-flex-direction(row);
                                    width: calc(66.66% + 16px);
                                    position: absolute;
                                    border: 0;
                                    border-radius: 0;
                                    top: 0 !important;
                                    left: auto;
                                    right: 0;
                                    min-width: inherit;
                                    box-shadow: none;
                                    margin-top: 0 !important;
                                    z-index: 1;
                                    background-color: $color-gray96;
                                }
                            }
                        }
                    }
                }

                .content-wrap {
                    width: 100%;

                    .megamenu-content {
                        width: 100%;

                        .mega-col-level-1 {
                            @include lib-vendor-prefix-display(flex);
                        }
                    }
                }

                .nav-item.level1 {
                    width: 100%;

                    @include min-screen($screen__m + 1) {
                        a {
                            &:hover {
                                background: transparent;
                            }
                        }
                    }

                    > .subitems-group {
                        span {
                            @include lib-font-size($font-size__base);
                            @include lib-css(font-weight, $font-weight__bold);
                            @include lib-css(font-family, $font-family__base);
                            text-transform: none;

                            &.opener {
                                display: none;
                            }
                        }
                    }

                    > .dropdown-mega {
                        .megamenu-content {
                            margin-top: 7px;
                        }

                        .nav-anchor {
                            padding: 5px 20px;

                            span {
                                @include lib-font-size($font-size__base);
                                @include lib-css(font-weight, $font-weight__regular);
                                @include lib-css(font-family, $font-family__base);
                                color: $color-black;
                            }

                            @include min-screen($screen__m + 1) {
                                &:hover {
                                    background: transparent;

                                    span {
                                        color: $theme__primary__color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .ves-menu {
        height: auto;

        .block-title {
            text-transform: none;
        }
    }

    .category-menu {
        li {
            @include min-screen($screen__m + 1) {
                &:hover {
                    .nav-anchor {
                        span {
                            color: $theme__primary__color;
                        }
                    }
                }
            }

            .nav-anchor {
                span {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__bold);
                    @include lib-css(font-family, $font-family__base);
                    color: $color-black;
                    text-transform: capitalize;
                }
            }
        }
    }

    .ves-megamenu {
        &.nav-mobile {
            .level-top {
                .submenu {
                    display: none !important; //override ves_megamenu css
                    height: auto !important;
                }

                &.anchor-active {
                    .submenu {
                        display: block !important;
                    }
                }
            }
        }
    }

    .secondary-menu {
        margin-bottom: 0;

        .ves-megamenu {
            @include lib-vendor-prefix-display(flex);
            background-color: transparent;
            justify-content: space-between;

            .level0 {
                margin: 0;

                &.submenu-left {
                    position: relative;

                    &:nth-of-type(1n+6)  {
                        .submenu {
                            left: auto;
                            right: 0;
                        }
                    }
                }

                &.phone-icon {
                    span {
                        display: flex;

                        &:before {
                            content: '';
                            display: inline-block;
                            background: url(lib-icon-svg-url($icon-base-phone-square, $color-gray1)) no-repeat;
                            background-size: cover;
                            width: 48px;
                            height: 45px;
                        }
                    }
                }

                > a {
                    @include lib-css(font-weight, $font-weight__bold);
                    text-transform: none;
                }

                &:hover {
                    > a {
                        background-color: $color-gray96;
                        color: $theme__primary__color;
                    }
                }

                .submenu {
                    min-width: 200px;
                    background-color: $color-gray96;
                    border: 0;
                    box-shadow: none;
                    border-radius: 0;

                    .active {
                        a {
                            border: 0;
                        }
                    }

                    .submenu {
                        background-color: transparent;
                    }

                    .subgroup {
                        &.level1 > a {
                            text-transform: none;
                        }
                    }
                }

                .caret,
                .opener {
                    display: none;
                }

                &.active {
                    > a {
                        background-color: $color-gray96;
                        color: $theme__primary__color !important;
                    }
                }
            }

            .content-wrap {
                width: 100%;

                .megamenu-content {
                    width: 100%;

                    .mega-col-level-1 {
                        @include lib-vendor-prefix-display(flex);
                    }
                }
            }
        }
    }
}

footer {
    .footer-menu-wrapper {
        background-color: $theme__primary__color;

        .footer-content {
            img {
                margin-right: 9px;
            }

            .ves-menu {
                margin-top: 29px;

                .ves-megamenu {
                    background-color: transparent;

                    > .nav-item {
                        padding: 0;

                        > .nav-anchor {
                            color: $color-white;
                            padding: 0;
                            background-color: transparent;

                            span {
                                &:first-child {
                                    @include lib-font-size($font-size__xl);
                                    @include lib-css(font-weight, $font-weight__bold);
                                }

                                &:not(:first-child) {
                                    display: none;
                                }
                            }

                            &:hover {
                                text-decoration: underline;
                            }
                        }

                        .submenu {
                            background-color: transparent;
                            box-shadow: none;
                            display: block;
                            width: 25%;
                            top: 0 !important;
                            position: relative;
                            border: 0;
                            color:  $footer-links-color;

                            .active {
                                > a {
                                    border-width: 0;
                                    border-color: transparent;
                                }
                            }

                            a {
                                color: $footer-links-color;
                                padding: 0;

                                &:hover {
                                    background-color: transparent;
                                    text-decoration: underline;
                                }
                            }

                            .nav-item {
                                padding: 0;

                                > .nav-anchor {
                                    margin-bottom: 5px;
                                    margin-top: 5px;
                                    padding: 0;
                                    line-height: $line-height__base;
                                    display: block;

                                    @include min-screen($screen__m + 1) {
                                        &:hover {
                                            color: $color-gray20;
                                        }
                                    }

                                    span {
                                        @include lib-css(font-weight, $font-weight__regular);
                                    }

                                    &:hover {
                                        color: inherit;
                                        text-decoration: underline;
                                    }
                                }
                            }
                        }

                        .megamenu-content {
                            @include lib-font-size($font-size__base);
                            @include lib-css(font-weight, $font-weight__regular);
                            line-height: 20px;
                        }


                        @include min-screen($screen__m + 1) {
                            &:hover {
                                .submenu {
                                    padding: 0;
                                }
                            }
                        }
                    }
                }
            }

            .block {
                &.widget {
                    margin-top: 29px;

                    .footer-title {
                        @include lib-font-size($font-size__xl);
                        @include lib-css(font-weight, $font-weight__bold);
                        @include lib-css(font-family, $font-family__base);
                        color: $color-white;
                        margin-bottom: 10px;
                        line-height: 40px;
                    }
                }
            }
        }
    }

    .footer-bottom-menu-wrapper {
        .footer-content {
            text-align: center;
        }

        .ves-menu {
            margin: 0 auto;
            display: inline-block;
            float: none;

            .block-title {
                display: none;
            }

            .navigation {
                background: transparent;
                margin-top: 20px;
            }
        }

        .ves-megamenu {
            display: inline-block;
            float: none;
            max-width: 1280px;
            background: transparent;

            > .nav-item {
                > .nav-anchor {
                    @include lib-css(font-weight, $font-weight__regular);
                    @include lib-css(font-family, $font-family__base);
                    text-transform: none;
                    color: $color-gray20;
                    padding: 0 17px;
                    border: 0;

                    @include min-screen($screen__m + 1) {
                        &:hover {
                            background: transparent;
                        }
                    }
                }
            }

            li {
                &.active>a {
                    background-color: transparent;
                    color: $color-gray20 !important;
                }
            }
        }
    }
}

.ves-container {
    .ves-menu {
        margin: 0;
    }

    .ves-horizontal {
        background: $color-gray-light0;
        margin-top: 13px;

        .nav-item {
            @include lib-css(font-family, $font-family__base);
            padding-left: 20px;

            @include min-screen($screen__m + 1) {
                &:hover {
                    > .nav-anchor {
                        background: $color-gray-light0;
                        color: $theme__primary__color;
                    }
                }
            }

            .nav-anchor {
                @include lib-css(font-weight, $font-weight__semibold);
                line-height: 40px;
                text-transform: none;
            }
        }
    }

    .ves-vertical {
        border: none;
        background: $color-white;

        .nav-item {
            @include lib-css(font-family, $font-family__base);
            padding-left: 0;
            margin-bottom: 14px;

            &.active {
                .nav-anchor {
                    color: $theme__primary__color !important;
                    background: $color-white;
                }
            }

            @include min-screen($screen__m + 1) {
                &:hover {
                    > .nav-anchor {
                        color: $theme__primary__color;
                        background: $color-white;
                    }
                }
            }

            .nav-anchor {
                @include lib-css(font-weight, $font-weight__semibold);
                text-transform: none;
                padding-left: 0;
                line-height: $line-height__s;
            }
        }
    }
}

@include min-screen($screen__m) {
    footer {
        .footer-menu-wrapper {
            .footer-content {
                .ves-megamenu {
                    > .nav-item {
                        width: calc(100% / 3);
                    }
                }
            }
        }
    }

    .nav-item {
        .submenu-left {
            &.dropdown-submenu {
                white-space: nowrap;
            }
        }
    }
}

@include max-screen($screen__m) {
    body {
        &.menu-open {
            overflow: hidden;
        }
    }

    .ves-container {

        .ves-horizontal {
            .nav-item {
                padding-left: 0;
            }
        }

        .ves-vertical {
            .nav-item {
                border: none;
                padding: 0 20px;

                &.active {
                    .nav-anchor {
                        border: none;

                        span:not(.ui-menu-icon) {
                            margin-left: 0;
                        }
                    }
                }

                &:last-child {
                    border: none;
                }
            }
        }
    }

    header {
        .col-menu {
            .category-button-container {
                padding: 16px 0;

                &.open {
                    background: transparent;

                    &:after {
                        display: block;
                    }
                }

                @include min-screen($screen__m + 1) {
                    &:hover {
                        background: transparent;
                    }
                }

                &:after {
                    @include lib-icon-svg($icon-base-menu, $color-white);
                    display: inline-block;
                    height: 32px;
                    width: 32px;
                }

                .category-button {
                    display: none;
                }
            }
        }

        .category-menu-wrapper {
            &.active {
                height: calc(100vh - 114px);
                overflow-y: scroll;
                background: $color-white;
                margin-top: 38px;
                padding-top: 20px;
                z-index: 1;

                .main-menu {
                    .ves-megamenu {
                        padding: 0 15px;

                        li {
                            a {
                                @include lib-font-size($font-size__base);
                                @include lib-css(font-weight, $font-weight__bold);
                                @include lib-css(font-family, $font-family__base);
                                line-height: $line-height__s;
                                padding: 12px 0;

                                .caret {
                                    display: none;
                                }
                            }

                            &.level0 {
                                border: 0;

                                &:first-child {
                                    > a {
                                        &:before {
                                            display: none;
                                        }
                                    }
                                }

                                a {
                                    border-bottom: 1px solid $color-gray82;
                                    @include make-col(12);

                                    > span {
                                        padding-left: 0;
                                        border: 0;

                                        &.opener {
                                            @include lib-vendor-prefix-display(flex);
                                            padding: 0;
                                            position: absolute;
                                            right: 0;
                                            width: 65px;
                                            height: 42px;
                                            top: 0;
                                            justify-content: center;

                                            &:before {
                                                display: inline-block;
                                                width: 11px;
                                                height: 11px;
                                            }
                                        }
                                    }
                                }

                                @include min-screen($screen__m + 1) {
                                    &:hover {
                                        > a {
                                            span {
                                                border: 0;
                                            }
                                        }
                                    }
                                }

                                @include min-screen($screen__m + 1) {
                                    &:hover {
                                        flex-direction: column;

                                        a {
                                            background: transparent;
                                            float: left;

                                            span {
                                                color: $color-black;
                                            }
                                        }

                                        &:after {
                                            display: none;
                                        }
                                    }
                                }

                                &.anchor-active {
                                    flex-direction: column;

                                    a {
                                        background: transparent;
                                        float: left;

                                        span {
                                            color: $color-black;
                                        }
                                    }

                                    &:after {
                                        display: none;
                                    }

                                    .opener {
                                        &:before {
                                            @include lib-icon-svg($icon-base-arrow-up, $color-gray20);
                                        }
                                    }

                                    .mega-col {
                                        @include lib-vendor-prefix-flex-direction(column);
                                    }
                                }

                                .submenu {
                                    .submenu {
                                        background-color: transparent;
                                        padding-left: 0;
                                    }
                                }
                            }
                        }

                        @include min-screen($screen__m + 1) {
                            &.menu-hover {
                                .subhover {
                                    &:hover {
                                        .dropdown-menu {
                                            position: relative;
                                            display: none;
                                            width: 100%;
                                            overflow: unset;
                                            border: 0;

                                            &:after {
                                                display: none;
                                            }

                                            .dropdown-mega {
                                                display: block;

                                                .megamenu-content {
                                                    margin: 0;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &.nav-mobile {
                            .subgroup {
                                &.level1 {
                                    > .submenu {
                                        > .content-wrap {
                                            > .megamenu-content {
                                                margin: 0;
                                            }
                                        }
                                    }

                                    .dropdown-mega {
                                        .nav-anchor {
                                            padding: 12px 0;
                                        }
                                    }

                                    .active {
                                        a {
                                            border-left: 0;
                                            border-color: $color-gray82;
                                        }
                                    }
                                }

                                .submenu {
                                    display: block;
                                }

                                span {
                                    &.opener {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .main-menu .level2.nav-dropdown {
        .row {
            margin-left: 0;
        }
    }

    footer {
        .footer-menu-wrapper {
            .footer-content {
                .ves-menu {
                    .ves-megamenu {
                        > .nav-item {
                            border: none;
                            .submenu {
                                display: none;
                                background: none;

                                .nav-item {
                                    .nav-anchor {
                                        span {
                                            line-height: 43px;
                                            margin-left: 0;
                                        }

                                        @include min-screen($screen__m + 1) {
                                            &:hover {
                                                color: $color-gray20;
                                            }
                                        }

                                        &:after {
                                            display: inline-block;
                                            width: 12px;
                                            height: 12px;
                                            @include lib-rotate(-90deg);
                                            float: right;
                                            margin-right: 7px;
                                            margin-top: 15px;
                                            @include lib-icon-svg($icon-base-arrow-down, $color-gray20);
                                            padding: 0;
                                        }
                                    }
                                }
                            }

                            .nav-anchor {
                                border-bottom: 1px solid $color-gray-light8;
                                height: 43px;
                                border-left: 0;

                                span {
                                    margin-left: 0;
                                }

                                span.opener {
                                    display: block;
                                    width: 12px;
                                    height: 12px;
                                    top: 15px;
                                    right: 3px;
                                    padding: 0;
                                    @include lib-rotate(-90deg);
                                    &:before {
                                        @include lib-icon-svg($icon-base-arrow-down, $color-gray20);
                                    }
                                }

                                &.item-active {
                                    color: $theme__primary__color;

                                    span.opener {
                                        top: 14px;
                                        right: 0;
                                        @include lib-rotate(0deg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .footer-bottom-menu-wrapper {
            .navigation {
                .nav-item {
                    border: none;
                    a {
                        @include lib-font-size($font-size__base);
                        line-height: $line-height__xs;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    header {
        .col-menu {
            .category-button-container {
                .category-button {
                    @include lib-font-size($font-size__xs);

                    &-title {
                        @include lib-font-size($font-size__xs);
                        display: inline-block;
                    }

                    &-down {
                        margin-left: 2px;
                        width: 8px;
                        height: 8px;
                        background-size: 8px 8px;
                    }
                }
            }
        }
    }

    footer {
        .footer-menu-wrapper {
            .footer-content {
                .ves-megamenu {
                    > .nav-item {
                        width: 50%;
                    }
                }
            }
        }
    }
}

@include screen($screen__m + 1, $screen__l) {
    header {
        .main-menu {
            .navigation {
                .ves-megamenu.menu-hover {
                    .subhover {
                        &:hover {
                            .dropdown-menu {
                                max-height: 80vh;
                                overflow-y: scroll;
                            }
                        }
                    }
                }
            }
        }
    }

    footer {
        .footer-menu-wrapper {
            .footer-content {
                .ves-menu {
                    .ves-megamenu {
                        .nav-item {
                            border: 0;
                            .submenu {
                                width: 100%;
                                background: transparent;
                                box-shadow: none;

                                .nav-dropdown {
                                    width: 100%;
                                    display: inline-block;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@include max-screen($screen__xl) {
    .secondary-menu {
        .ves-megamenu {
            li {
                &.level0 {
                    > a {
                        font-size: calc(#{$font-size__xs} + (16 - 10) * ((100vw - 300px) / (1600 - 300)));
                    }
                }
            }
        }
    }
}

@include min-screen($screen__xl) {
    .secondary-menu {
        .ves-megamenu {
            li {
                &.level0 {
                    > a {
                        @include lib-font-size($font-size__m);
                    }
                }
            }
        }
    }
}
