//
//  Variables
//  _____________________________________________

$review-ratings-left: 280px !default;
$reviews-indent-desktop: 30px !default;
.rating-summary {
  @include lib-rating-summary();
  .rating-result {
    margin-left: -5px;
  }

  .label {
    &.rating-label {
      font-weight: $font-weight__bold;
      display: block;
    }
  }
}

.product-reviews-summary,
.table-reviews {
  .rating-summary {
    @include lib-rating-summary-label-hide();
  }
}

.review-control-vote {
  @include lib-rating-vote();
}

.tab_reviews {
  .counter {
    display: none;
  }
}

//
//  Add review block
//  ---------------------------------------------

.block {
  &.add.review {
    margin-bottom: $indent__xl;

    > .title {
      @include lib-heading(h2);
    }

    .legend {
      @include lib-heading(h3);
      margin: 0 0 $indent__base;
    }

    .field.rating {
      display: table-row;

      .label {
        display: table-cell;
        padding: $indent__xs 0;
        vertical-align: middle;
      }

      .control {
        display: table-cell;
        vertical-align: top;
      }
    }
  }

  &.reviews.list {
    > .title {
      @include lib-heading(h2);
    }

    .review {
      &.item {
        margin-bottom: $indent__xl;
      }

      &.title {
        @include lib-heading(h3);
      }
    }
  }
}

.data.table.reviews {
  .rating-summary {
    margin-top: -4px;
  }
}

.customer-review.view {
  margin-bottom: $indent__base;

  .review.title,
  .ratings-summary,
  .product-details {
    margin-bottom: $indent__base;
  }
}

.product-reviews-summary {
  display: table;
  margin: $indent__s 0 12px;

  .products.wrapper.list & {
    margin: 0 auto;
  }

  &.empty {
    display: none;
  }

  .rating-summary {
    display: table-cell;
    left: -$indent__xs;
    position: relative;
    text-align: left;
    vertical-align: middle;
  }

  .reviews-actions {
    display: table-cell;
    line-height: $rating-icon__font-size;
    vertical-align: middle;

    a {
      margin-right: $indent__xs;
      text-decoration: underline;
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .product-reviews-summary {
    .products.wrapper.list & {
      margin: 0;
    }
  }
}

.customer-review {
  .product-details {
    @extend .abs-add-clearfix;
    margin-bottom: $indent__xl;
  }

  .product-media {
    float: left;
    margin-right: 3%;
    max-width: 285px;
    width: 30%;
  }

  .review-details {
    .customer-review-rating {
      @include lib-css(margin-bottom, $indent__base);

      .item {
        @include lib-css(margin-bottom, $indent__s);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .review-title {
      @include lib-heading(h3);
      @include lib-css(font-weight, $font-weight__semibold);
      @include lib-css(margin-bottom, $indent__base);
    }

    .review-content {
      @include lib-css(margin-bottom, $indent__base);
      @include lib-font-size($font-size__m);
    }
  }

  .product-reviews-summary {
    .rating-summary,
    .reviews-actions {
      display: block;
    }
  }
}

.review-field-ratings {
  .nested {
    display: table;
  }

  .review-field-rating {
    display: table-row;

    .label,
    .control {
      display: table-cell;
      padding-bottom: $indent__s;
      vertical-align: top;
    }

    .label {
      padding-right: $indent__s;
      padding-top: 6px;
    }

    .control {
      padding-top: 2px;
    }
  }
}

//
//  Review product page
//  ---------------------------------------------

.review {
  &-toolbar {
    border-top: 1px solid $color-gray79;
    margin: 0 0 $indent__base;
    text-align: center;

    &:first-child {
      display: none;
    }

    .pages {
      border-bottom: 1px solid $color-gray79;
      display: block;
      padding: $indent__base 0;
      text-align: center;
    }

    .limiter {
      display: none;
    }
  }

  &-add {
    display: none;

    .block-title {
      display: none;
    }
  }

  &-form {
    .action.submit.primary {
      @include lib-button-primary();
    }
  }

  .fieldset &-legend.legend {
    @extend .abs-reset-left-margin;
    margin-bottom: $indent__base;

    strong {
      display: block;
      margin-left: 0;
    }
  }

  &-field-rating {
    .control {
      margin-bottom: 1.2 * $indent__xl;
      margin-top: $indent__s;
    }
  }

  &-list {
    margin-bottom: $indent__l;

    .block-title {
      display: none;
    }

    .detail-title {
      .counter {
        display: none;
      }
    }

    a {
      &.review-add {
        @include lib-button-primary();
        float: right;
        margin-top: -50px;
        text-decoration: none;
      }
    }
  }

  &-item {
    @include lib-clearer();
    border-bottom: 1px solid $color-gray79;
    margin: 0;
    padding: 15px 0;

    &:last-child {
      border-width: 0;
    }
  }

  &-ratings {
    display: table;
    margin-bottom: $indent__s;
    max-width: 100%;
    background: $color-gray-light0;
    padding: 10px 20px 30px 25px;
  }

  &-date {
    @include lib-font-size($font-size__base);
    font-style: italic;
  }

  &-title {
    @include lib-font-size($font-size__l);
    @include lib-css(font-family, $font-family__base);
    @include lib-css(font-weight, $font-weight__semibold);
    margin: 0 0 $indent__s;
  }

  &-content {
    @include lib-font-size($font-size__m);
    margin-bottom: 15px;
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__s) {
  .review {
    &-form {
      max-width: 500px;
    }

    &-ratings {
      float: left;
      margin-bottom: 0;
      max-width: $review-ratings-left - $indent__xl;
    }

    &-ratings ~ &-content,
    &-ratings ~ &-details {
      margin-left: $review-ratings-left;
    }

    &-toolbar {
      margin: 0 0 $reviews-indent-desktop;

      .pages {
        padding: $reviews-indent-desktop 0;
      }
    }

    .fieldset &-legend.legend {
      margin-bottom: $reviews-indent-desktop;
    }

    &-item {
      padding: 15px 0;
    }

    &-title {
      margin: 0 0 $indent__s;
    }
  }
}

.product-info-details-container {
  .counter {
    display: none;
  }
}