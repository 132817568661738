$product-grid-items-per-row-layout-default              : 2 !default;

$product-grid-items-per-row-layout-1-screen-s           : 3 !default;
$product-grid-items-per-row-layout-1-screen-m           : 4 !default;
$product-grid-items-per-row-layout-1-screen-l           : 5 !default;

$product-grid-items-per-row-layout-2-left-screen-s      : 3 !default;
$product-grid-items-per-row-layout-2-left-screen-m      : 4 !default;
$product-grid-items-per-row-layout-2-left-screen-l      : '' !default;

$product-grid-items-per-row-layout-2-right-screen-s     : 3 !default;
$product-grid-items-per-row-layout-2-right-screen-m     : 4 !default;
$product-grid-items-per-row-layout-2-right-screen-l     : '' !default;

$product-grid-items-per-row-layout-3-screen-s           : 3 !default;
$product-grid-items-per-row-layout-3-screen-m           : '' !default;
$product-grid-items-per-row-layout-3-screen-l           : '' !default;

$product-grid-items-padding                             : 0 $indent__base $indent__base !default;
$product-grid-items-margin                              : 0 0 $indent__s !default;

$product-name-text-decoration                           : none !default;
$product-name-text-decoration-hover                     : $link__hover__text-decoration !default;

$toolbar-mode-icon-font-size                            : 26px !default;
$product-h1-margin-bottom-desktop                       : $indent__base !default;

$catalog-title-color-dark                               : $color-gray20;
$catalog-background-color-dark                          : $color-gray20;
$catalog-background-color-lighten                       : $color-gray-light0;
$catalog-background-color-light                         : $color-white;
$table-background-color-light                           : $color-gray-light01;
$table-background-color-dark                            : $color-gray90;
$catalog-text-color                                     : $color-gray20;
$catalog-text-color-light                               : $color-white;
$catalog-border-color                                   : $color-gray78;
$catalog-border-color-dark                              : $color-gray20;
$catalog-border-color-middle                            : $color-gray89;
$catalog-border-color-lighten                           : $color-gray82;
$catalog-border-color-light                             : $color-gray-light0;

@import 'module/listings';
@import 'module/toolbar';
@import 'module/gallery';

//
//  Category view
//  ---------------------------------------------

.old-price,
.old.price {
    text-decoration: line-through;
    float: left;
    display: none !important;
}

.prices-tier {
    .price-container {
        .price-including-tax {
            + .price-excluding-tax {
                &:before {
                    content: '(' attr(data-label) ': ';
                }

                &:last-child {
                    &:after {
                        content: ')';
                    }
                }
            }
        }

        .weee[data-label] {
            display: inline;

            .price {
                @include lib-font-size(11);
            }

            &:before {
                content: ' +' attr(data-label) ': ';
            }
        }
    }
}

.actual-price {
    @include lib-css(font-weight, $font-weight__bold);
}

.product {
    &.name {
        a {
            @extend .abs-product-link;
        }
    }

    &.media {
        position: relative;

        .labels-container {
            position: absolute;
            top: 15px;
            right: 0;
            z-index: 99;

            .label {
                @include lib-font-size($font-size__xs);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(padding, 10px 18px);
                text-transform: uppercase;
                height: 40px;
                line-height: $line-height__xl;
                float: right;
                clear: both;
                margin-bottom: 10px;

                &.onze-keuze {
                    background: $product-label-1;
                    color: $color-white;
                }

                &.nieuw {
                    background: $product-label-2;
                    color: $color-white;
                }

                &.aanbieding,
                &.outlet {
                    background: $product-label-3;
                    color: $theme__primary__color;
                }
            }
        }
    }
}

.category {
    &-image {
        .image {
            display: block;
            height: auto;
            max-width: 100%;
        }
    }

    &-image,
    &-description {
        margin-bottom: $indent__base;
    }

    &-additional-content,
    &-description {
        p {
            @include lib-font-size($font-size__m);
            line-height: $line-height__l;
        }
    }

    &-description {
        p {
            display: inline;
        }

        a {
            &.scroll-to-content {
                text-decoration: underline;
                color: $color-gray60;
            }
        }
    }
}

//
//  Product images general container
//  ---------------------------------------------

.product-image {
    &-container {
        display: inline-block;
        max-width: 100%;
    }

    &-wrapper {
        display: inline-block;
        height: 0;
        overflow: hidden;
        z-index: 1;
    }

    &-photo {
        bottom: 0;
        display: block;
        height: auto;
        max-height: calc(100% - 1px);
        left: 0;
        margin: auto;
        max-width: 100%;
        position: absolute;
        right: 0;
        top: 0;
    }
}

//
//  Product view
//  ---------------------------------------------

.product {
    &.media {
        .product {
            &.photo {
                .photo {
                    &.image {
                        @extend .abs-adaptive-images-centered;
                    }
                }
            }
        }

        .placeholder {
            .photo {
                &.container {
                    max-width: 100%;
                }
            }
        }

        .notice {
            @include lib-css(color, $text__color__muted);
            @include lib-font-size($font-size__s);
            margin: $indent__s 0;
        }

        .product {
            &.thumbs {
                margin: $indent__base 0 $indent__l;
            }
        }

        .items {
            &.thumbs {
                @include lib-list-inline();

                .active {
                    display: block;
                    line-height: 1;
                }
            }
        }
    }
}

.product {
    &.info {
        &.detailed {
            clear: both;
            margin-bottom: 30px;
            @include lib-vendor-prefix-display(flex);

            .additional-attributes {
                width: auto;
                @include lib-table-resize(
                    $_th-padding-left: 0,
                    $_th-padding-right: $indent__l,
                    $_th-padding-bottom: $indent__s,
                    $_td-padding-bottom: $indent__s
                );
            }
        }
    }
}

.product-info-main {
    .page-title-wrapper {
        .page-title {
            line-height: $line-height__base;
            margin-bottom: $indent__s;
        }
    }

    .stock {
        &.available,
        &.unavailable,
        &.request,
        &.customizable-product {
            @include lib-css(font-weight, $font-weight__regular);
            @include lib-vendor-prefix-display(flex);
            margin-right: $indent__base;
            vertical-align: top;
        }
    }

    .product-advantage {
        margin-bottom: 20px;

        li:last-of-type {
            margin-bottom: 0;
        }

        &.cms-block {
            margin-bottom: 35px;
        }
    }

    .product {
        &.attribute {
            &.sku {
                @include lib-css(color, $text__color__muted);
                display: inline-block;
                vertical-align: top;

                > .value {
                    display: inline-block;
                    vertical-align: top;
                    word-break: break-all;
                }

                .type {
                    margin-right: $indent__xs;
                }
            }

            &.overview {
                margin: $indent__base 0;
            }
        }

        &.alert {
            margin: $indent__s 0;
        }
    }

    .price-box {
        margin-top: 18px;

        &.price-tier_price {
            margin-top: 12px;
        }
    }

    .product-reviews-summary {
        .reviews-actions {
            @include lib-font-size($font-size__base);
        }
    }

    &-wrapper {
        &.fixed {
            position: fixed;
            left: 0;
            right: 0;
            z-index: 999;
            top: 80px;
            height: 80px;
            background-color: $catalog-background-color-lighten;

            .page-main {
                @include lib-vendor-prefix-display(flex);
                align-items: center;
                padding: 0;
                margin-top: 0;
                height: 80px;
            }

            .marketingrule,
            .usp-container,
            .product-social-links {
                display: none;
            }

            .product-info-price {
                .price-box {
                    &.price-final_price {
                        @include lib-vendor-prefix-display(flex);
                        @include lib-vendor-prefix-flex-direction(row-reverse);
                    }
                }

                .price-box {
                    .special-price {
                        .price {
                            &.price {
                                @include lib-font-size($font-size__xxxl);
                                @include lib-css(font-weight, $font-weight__bold);
                                @include lib-css(font-family, $font-family__base);
                            }
                        }
                    }
                }

                .old-price {
                    @include lib-vendor-prefix-display(flex);
                    align-items: flex-end;
                }
            }

            .product-add-form {
                .box-tocart {
                    margin: 0;
                    width: 100%;

                    .actions {
                        width: 100%;

                        .tocart {
                            margin-right: 0;
                        }
                    }

                    .field.qty {
                        display: none;
                    }
                }
            }

            h1 {
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(font-family, $font-family__base);
                color: $catalog-title-color-dark;
                margin: 0;
            }

            .product-options-wrapper {
                display: none;
            }

            .action {
                &.primary {
                    &.tocart {
                        display: none;

                        &.hidden {
                            &.sticky-button-to-top {
                                display: block !important;
                            }
                        }
                    }
                }
            }

            .fixed-column {
                &-title {
                    h1 {
                        &.page-title {
                            @include lib-font-size($font-size__m);
                        }
                    }
                }

                &-img {
                    height: 100%;
                    background-repeat: no-repeat;
                    background-size: contain;
                    background-position: center right;
                }
            }

            .prices-tier {
                display: none;
            }
        }
    }

    .product-options-wrapper {
        .field {
            label {
                @include lib-font-size($font-size__l);
                @include lib-css(font-weight, $font-weight__bold);
                @include lib-css(font-family, $font-family__base);
                margin-bottom: 0;
            }
        }
    }

    .usp-container {
        margin-top: 7px;
        display: inline-block;
    }

    .price-ex-vat {
        margin-left: 5px;
        order: -1;
        align-self: center;
    }

    .price-incl-vat {
        width: 100%;
        color: $primary__color;
    }

    .business-reference {
        margin: 10px 0;
        text-align: right;
    }
}

.product-info-main,
.checkout-usps {
    ul {
        list-style-type: none;
        padding-left: 0;
        position: relative;

        li {
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            line-height: $line-height__s;
            height: 18px;
            margin-bottom: 15px;
            margin-left: 30px;

            &:before {
                content: url(lib-icon-svg-url($icon-kuras-check, $theme__secondary__color));
                display: inline-block;
                left: 0;
                position: absolute;
                height: 18px;
                width: 18px;
            }
        }

        &.prices-tier {
            li {
                font-style: italic;

                &:before {
                    display: none;
                }

                .price-wrapper {
                    .price {
                        @include lib-font-size($font-size__base);
                        @include lib-css(font-weight, $font-weight__bold);
                        margin: 0 5px;
                    }
                }

                .benefit {
                    @include lib-css(font-weight, $font-weight__regular);
                    margin: 0 5px;

                    .percent {
                        @include lib-css(font-weight, $font-weight__bold);
                    }
                }
            }
        }
    }

    .usp-title {
        @include lib-font-size($font-size__m);
        @include lib-css(font-weight, $font-weight__semibold);
        line-height: $line-height__s;
        color: $theme__primary__color;
        margin-bottom: 16px;
    }
}

.product-options-wrapper {
    .fieldset-product-options-inner {
        .legend {
            @include lib-css(font-weight, $font-weight__bold);
            @include lib-css(margin, 0 0 $indent__xs);
            @include lib-font-size(14px);
            border: none;
            display: inline-block;
            float: none;
            padding: 0;
        }

        //  Date & Time custom option (Affect Time that goes only after Date)
        input.datetime-picker {
            ~ select.datetime-picker {
                margin-top: $indent__s;
            }
        }

        &.required,
        &._required {
            .legend {
                &:after {
                    content: '*';
                    @include lib-typography(
                        $_font-size: $form-field-label-asterisk__font-size,
                        $_color: $form-field-label-asterisk__color,
                        $_font-family: $form-field-label-asterisk__font-family,
                        $_font-weight: $form-field-label-asterisk__font-weight,
                        $_line-height: $form-field-label-asterisk__line-height,
                        $_font-style: $form-field-label-asterisk__font-style
                    );
                    @include lib-css(margin, $form-field-label-asterisk__margin);
                }
            }
        }
    }

    .field {
        .note {
            display: block;
        }

        .price-notice {
            @extend .abs-adjustment-incl-excl-tax;
        }
    }
}

.product-info-main,
.product-options-bottom {
    .price-box {
        .price-including-tax + .price-excluding-tax,
        .weee + .price-excluding-tax,
        .weee {
            @include lib-font-size($font-size__s);
            line-height: 14px;
            margin-bottom: $indent__xs;
        }

        .price-incl-vat {
            width: 100%;
            @include lib-font-size($font-size__base);
            color: $color-gray62;
        }


        .price-excl-vat {
            @include lib-font-size($font-size__xl);
            color: $primary__color;
            font-weight: $font-weight__bold;

            .price-final_price {
                margin-left: -3px;
                display: inline-block;
            }
        }

        .price {
            white-space: nowrap;
        }

        .old-price,
        .special-price {
            margin: 0;

            .price-label {
                display: none;

                &:after {
                    content: ': ';
                }
            }
        }

        .old-price {
            margin-right: 10px;

            .price {
                @include lib-font-size($font-size__m);
                color: $color-gray48;
            }
        }
    }

    .special-price {
        display: inline-block;
        margin: $indent__s 0;

        .price-container {
            @include lib-font-size($font-size__base);
        }

        .price-label + .price-wrapper {
            display: inline-block;
        }
    }

    .box-tocart {
        margin: $indent__base 0;
        width: 100%;

        .field {
            &.qty {
                width: 200px;
                height: 50px;
                float: left;
                margin-bottom: 0;

                .control {
                    width: 100%;
                    margin: 0;
                    height: 50px;
                    border: none;

                    .qty-plus,
                    .qty-minus {
                        width: 50px;
                        height: 50px;
                        float: left;
                        cursor: pointer;
                        text-align: center;
                        background-color: $page__detail__background-color;

                        div {
                            width: 12px;
                            height: 50px;
                            display: inline-block;
                        }
                    }

                    .qty-plus {
                        border-left: 2px solid $theme__primary__border__color;

                        div {
                            background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray20)) no-repeat center center;
                        }

                        &:hover {
                            div {
                                background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
                            }
                        }
                    }

                    .qty-minus {
                        border-right: 2px solid $theme__primary__border__color;

                        div {
                            background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray20)) no-repeat center center;
                        }

                        &:hover {
                            div {
                                background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
                            }
                        }
                    }

                    .input-text {
                        &.qty {
                            @include lib-font-size($font-size__m);
                            @include lib-css(font-weight, $font-weight__semibold);
                            width: 100px;
                            height: 50px;
                            float: left;
                            border: 0;
                            vertical-align: top;
                            margin: 0;
                            padding: 0;
                            color: $color-gray20;
                        }
                    }
                }

            }
        }

        .input-text {
            &.qty {
                $tocart-input-size: $button__line-height__l + 30px;
                height: $tocart-input-size;
                text-align: center;
                width: $tocart-input-size;
                background-color: $page__detail__background-color;
            }
        }

        .actions {
            float: left;
            width: calc(100% - 223px);
            max-width: none;
            padding: 0;
        }

        .action {
            &.tocart {
                @include lib-button-checkout();
                color: $theme__primary__color;
                width: 100%;
                height: 50px;

                span {
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__bold);
                    background: url(lib-icon-svg-url($icon-base-cart, $theme__primary__color)) no-repeat center right;
                    padding-right: 34px;
                }
            }
        }
    }

    .product-addto-links {
        margin: $indent__m 0;

        a {
            &.action {
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                display: block;
                min-height: 18px;
                padding-left: 26px;
                margin-bottom: 12px;
                text-transform: none;
                border: none;

                span {
                    color: $catalog-text-color;
                }

                &:hover {
                    border: none;
                    background-color: transparent;
                }

                &.tocompare {
                    background: transparent url(lib-icon-svg-url($icon-base-add-to-compare, $color-gray61)) no-repeat 2px 4px;
                    background-size: 16px 16px;
                }
            }
        }
    }

    .action {
        &.tocompare {
            @extend .abs-action-addto-product;
            vertical-align: top;
        }
    }

    .marketingrule {
        @include lib-font-size($font-size__base);
        @include lib-css(font-weight, $font-weight__regular);
        line-height: $line-height__s;
        font-style: italic;
        margin-top: 11px;
    }
}

.prices-tier {
    @extend .abs-reset-list;
    margin: $indent__s 0 20px;

    .price-container {
        display: inline-block;
    }

    .price-including-tax,
    .price-excluding-tax,
    .weee {
        display: inline-block;

        .price {
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__bold);
        }
    }
}

.ui-dialog-titlebar-close {
    @include lib-button-as-link();
}

.block {
    &.related {
        border-top: 1px solid $color-gray64;

        .action {
            text-align: center;

            &.select {
                margin: 0 $indent__xs;
            }
        }
    }
}

.product-usps {
    margin-top: 37px;
}

.page-wrapper {
    > .page-bottom {
        max-width: 100%;
        padding: 0;
        margin: 0;
    }

    .product {
        &.info {
            &.detailed {
                &.fixed {
                    background-color: $catalog-background-color-light;
                    position: fixed;
                    width: 100%;
                    z-index: 999;
                    top: 60px;

                    &.with-addtocart {
                        top: 160px;
                    }

                    .page-main {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .all-specs,
    .product-info-details-container {
        margin-bottom: 38px;

        ul {
            &.tab-group {
                @include lib-vendor-prefix-display(flex);
                list-style-type: none;
                margin: 0;
                padding: 10px 28px;
                background-color: $page__detail__background-color;

                li {
                    float: left;
                    display: inline-block;
                    margin: 8px 30px 8px 0;

                    a {
                        @include lib-css(font-family, $font-family__base);
                        @include lib-font-size($font-size__l);
                        @include lib-css(font-weight, $font-weight__regular);
                        line-height: $line-height__xl;
                        display: block;
                        color: $catalog-text-color;
                    }
                }

                li {
                    &.active {
                        a {
                            @include lib-css(font-weight, $font-weight__bold);
                            color: $theme__primary__color;
                        }
                    }
                }
            }
        }

        ul {
            &.product-advantage {
                margin: 35px 0;
                list-style-type: none;
                padding-left: 0;

                li {
                    @include lib-css(font-family, $font-family__base);
                    @include lib-font-size($font-size__m);
                    @include lib-css(font-weight, $font-weight__regular);
                    line-height: $line-height__s;
                    padding-left: 33px;
                    background-image: url(lib-icon-svg-url($icon-base-check, $theme__secondary__color));
                    background-repeat: no-repeat;
                    background-size: 16px 22px;
                    background-position: 2px 0;
                    margin-bottom: 6px;
                }
            }
        }

        .detail-title {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__xxl);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__s;
            color: $catalog-title-color-dark;
            margin-bottom: 10px;
            margin-top: 30px;
        }

        .product {
            &.attribute {
                @include lib-font-size($font-size__m);
                line-height: $line-height__l;
            }
        }

        ul {
            &.additional-attributes {
                list-style-type: none;
                padding-left: 0;
                margin-left: 0;
                margin-bottom: 26px;

                li {
                    div {
                        @include lib-css(font-family, $font-family__base);
                        @include lib-font-size($font-size__base);
                        @include lib-css(font-weight, $font-weight__regular);
                        @include lib-vendor-prefix-display(flex);
                        line-height: $line-height__l;
                        padding: 10px;

                        &:first-of-type {
                            border-right: 1px solid $catalog-background-color-light;
                        }
                    }
                }

                li {
                    &:nth-child(odd) {
                        background-color: $table-background-color-light;
                    }

                    &:nth-child(even) {
                        background-color: $table-background-color-dark;
                    }
                }

                li {
                    @include lib-vendor-prefix-display(flex);
                    margin-bottom: 0;
                    padding: 0;

                    .info-icon {
                        width: 16px;
                        background-image: url(lib-icon-svg-url($icon-base-exclamation-circle, $theme__primary__color));
                        background-repeat: no-repeat;
                        background-size: 16px;
                        background-position: 0;
                        height: 16px;
                        display: inline-block;
                        cursor: pointer;
                        flex: 0 0 16px;
                        margin-top: 4px;
                        margin-right: 10px;

                        .tooltip-popup {
                            display: none;
                        }

                        &.no-tooptip {
                            background: none;
                            cursor: auto;
                        }

                        &:hover {
                            .tooltip-popup {
                                display: block;
                            }
                        }
                    }
                }
            }
        }

        .tooltip-popup {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            position: absolute;
            background: $theme__primary__color;
            border: 1px solid $catalog-border-color-dark;
            padding: 10px;
            z-index: 99;
            top: 0;
            left: 37px;
            width: 100%;
            line-height: $line-height__l;

            p {
                @include lib-css(font-family, $font-family__base);
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__l;
                margin-bottom: 0;
            }
        }

        a {
            &.more-attributes {
                @include lib-css(font-family, $font-family__base);
                @include lib-font-size($font-size__m);
                @include lib-css(font-weight, $font-weight__regular);
                line-height: $line-height__s;
                text-decoration: underline;
                color: $theme__primary__color;
            }
        }
    }
}

.page-main {
    .all-specs {
        &.page-main {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.catalog-product-view {
    .page-wrapper {
        background-color: $page__background-color;
    }

    .page-title-wrapper {
        &.product {
            h1 {
                &.page-title {
                    @include lib-font-size($font-size__xxl);
                    margin-bottom: 15px;
                }
            }
        }
    }

    .short-description {
        a {
            text-decoration: underline;
        }
    }

    .catalog_product_related {
        .detail-title {
            padding-bottom: 10px;
            border-bottom: 1px solid $theme__primary__border__color;
        }
    }

    .product_info_description {
        table {
            @include lib-font-size($font-size__base);
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;

            tr {
                display: flex;

                td {
                    width: 100%;
                    border-right: 1px solid $color-white;

                    &:last-child {
                        border-right: none;
                    }
                }

                &:nth-of-type(odd) {
                    background-color: $color-gray-light01;
                }

                &:nth-of-type(even) {
                    background-color: $color-gray90;
                }
            }
        }
    }

    .usp-help {
        background-color: $theme__secondary__color;
        color: $theme__primary__color;
        padding: 15px 32px;
        margin-top: 35px;

        h3 {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__l);
            @include lib-css(font-weight, $font-weight__bold);
            line-height: $line-height__l;
            margin-bottom: 17px;
            position: relative;
            z-index: 1;
        }

        ul {
            margin: 0;
            padding: 0;
            position: relative;
            z-index: 1;

            li {
                @include lib-vendor-prefix-display(flex);
                margin-bottom: 15px;

                a {
                    @include lib-css(font-weight, $font-weight__bold);
                    color: $theme__primary__color;
                    text-decoration: underline;
                }
            }
        }

        .icon {
            height: 20px;

            &:before {
                display: inline-block;
                width: 23px;
                margin-right: 20px;
            }

            &-chat {
                &:before {
                    @include lib-icon-svg($icon-base-chat, $theme__primary__color);
                }
            }

            &-call {
                &:before {
                    @include lib-icon-svg($icon-base-phone, $theme__primary__color);
                }
            }

            &-mail {
                &:before {
                    @include lib-icon-svg($icon-base-mail, $theme__primary__color);
                }
            }

            &-headset {
                &:before {
                    @include lib-icon-svg($icon-base-headset, $theme__primary__color);
                }
            }
            &-whatsapp {
                &:before {
                    @include lib-icon-svg($icon-kuras-whatsapp, $theme__primary__color);
                }
            }
        }

        img {
            position: absolute;
            right: 37px;
            bottom: 0;
        }
    }

    .brands-container {
        position: absolute;
        right: 20px;
        z-index: 9;
    }

    .product-info-col {
        background-color: $page__detail__background-color;
        padding: 0 28px 30px;
    }

    .block.widget.upsell {
        padding-top: 30px;
    }
}

.product-banner {
    .product-item-info {
        background-color: $theme__secondary__color;

        .banner {
            background-image: url('../images/plp-banner.jpg');
            background-size: cover;
            height: 210px;
        }

        .content {
            text-align: center;
            padding: 15px;

            h2 {
                margin-top: 1.4rem;
                line-height: $line-height__s;
            }

            p {
                @include lib-css(font-family, $font-family__base);
                @include lib-font-size($font-size__l);
                @include lib-css(font-weight, $font-weight__bold);
                margin-top: 30px;
                margin-bottom: 30px;
            }
        }
    }
}
//
//  Sidebar product view
//  ---------------------------------------------

.sidebar {
    .product-items {
        .product-item {
            margin-bottom: $indent__base;
            position: relative;

            &-info {
                position: relative;
                width: auto;

                .product-item-photo {
                    left: 0;
                    position: absolute;
                    top: 0;
                }
            }

            &-name {
                margin-top: 0;
            }

            &-details {
                margin: 0 0 0 85px;
            }

            &-actions {
                display: block;
                margin-top: $indent__s;
            }
        }

        .price-box {
            display: block;
            margin: 7px 0;
        }

        .text {
            margin-right: 8px;
        }

        .counter {
            @include lib-css(color, $primary__color__lighter);
            @include lib-font-size($font-size__s);
            white-space: nowrap;
        }

        .minilist {
            .price {
                display: inline;
                padding: 0;
            }

            .weee {
                &:before {
                    display: inline-block;
                }
            }
        }
    }

    .action {
        &.delete {
            @extend .abs-remove-button-for-blocks;
            position: absolute;
            right: 0;
            top: 0;
        }
    }

    .subtitle {
        @extend .abs-no-display;
    }

    //
    //  Product images only
    //  ---------------------------------------------

    .product-items-images {
        @extend .abs-add-clearfix;
        margin-left: -$indent__xs;

        .product-item {
            @extend .abs-add-box-sizing;
            float: left;
            padding-left: $indent__xs;
        }
    }

    //
    //  Product names only
    //  ---------------------------------------------

    .product-items-names {
        .product-item {
            margin-bottom: $indent__s;
        }

        .product-item-name {
            margin: 0;
        }
    }

    .block-reorder {
        .block-title {
            strong {
                &#block-reorder-heading {
                    @include lib-font-size($font-size__m);
                }
            }
        }

        .block-content {
            margin-top: 10px;
        }

        .product-item {
            @include lib-vendor-prefix-display(flex);

            .field.item {
                margin-right: 10px;
            }

            .checkbox {
                top: 0;
                margin-top: 0;
            }

            input[type="checkbox"] {
                &:before {
                    @include lib-icon-svg($icon-base-add-to-compare, $color-gray60);
                    top: -2px;
                    position: relative;
                    background: $color-white;
                    flex: 0 0 15px;
                    height: 15px;
                    width: 15px;
                    display: inline-block;
                    cursor: pointer;
                }

                &:hover,
                &:checked {
                    &:after {
                        @include lib-icon-svg($icon-base-check, $color-gray20);
                        width: 10px;
                        height: 9px;
                        display: inline-block;
                        left: 3px;
                        top: -6px;
                        position: absolute;
                        cursor: pointer;
                    }
                }
            }

            &-name {
                @include lib-font-size($font-size__base);
                @include lib-css(font-weight, $font-weight__regular);
                min-height: unset;
            }
        }
    }
}

.superscript {
    position: absolute;
    margin-top: -1px;
    margin-left: -3px;
    font-size: 7px;
}

@include max-screen($screen__l + 1) {
    .product-info-main {
        .box-tocart {
            .actions {
                .action {
                    &.tocart {
                        padding: 12px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__l) {
    .product-info-main {
        ul.prices-tier {
            li {
                height: unset;
            }
        }
    }
}

@include min-screen($screen__l + 1) {
    .product-info-main {
        ul.prices-tier {
            li {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(center);
            }
        }
    }
}

@include screen($screen__m, $screen__l, $orientation: portrait) {
    .product-info-main {
        .box-tocart {
            .actions {
                width: 100%;
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .catalog-product-view {
        .column {
            &.main {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-prefix-flex-direction(column);
            }
        }

        .product {
            &.media {
                @include lib-vendor-prefix-order(-1);
            }
        }

        .product_info_description {
            table {
                tbody {
                    width: 100%;
                    overflow-x: scroll;

                    tr {
                        > td {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .block {
        &.related {
            .action {
                &.select {
                    display: block;
                    margin: $indent__xs 0;
                }
            }
        }
    }

    .compare {
        display: none;
    }

    .product {
        &-info-main {
            margin-top: 30px;

            .product-options-wrapper {
                .field {
                    label {
                        @include lib-font-size($font-size__m);
                        margin-bottom: 5px;
                    }
                }
            }

            .box-tocart {
                overflow: hidden;

                .actions {
                    width: 100%;
                    margin-top: 15px;

                    .action {
                        &.tocart {
                            @include lib-button-checkout();
                            padding: 12px;
                            color: $theme__primary__color;
                        }
                    }
                }
            }
        }

        &-addto-links {
            .action {
                &.tocompare {
                    display: none;
                }
            }
        }

        &-item-actions {
            .actions-secondary {
                > .action {
                    &.tocompare {
                        display: none;
                    }
                }
            }
        }
    }

    [class*='block-compare'] {
        display: none;
    }

    .brands-container {
        &.no-thumbnails,
        &.have-thumbnails {
            bottom: 20px;
        }

        .brand-image {
            max-width: 80px;
        }
    }

    .catalog-category-view {
        &.filter-active {
            overflow-y: hidden;
            height: 100vh;
        }

        .column {
            &.main {
                order: 2;
            }
        }

        .sidebar {
            .filter {
                .filter-title {
                    display: block;

                    strong {
                        background: $theme__secondary__color;
                        padding: 10px;
                        display: block;
                        text-align: center;
                    }
                }

                .filter-content {
                    display: none;
                }

                &.active {
                    display: block;
                    position: fixed;
                    background: $page__detail__background-color;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100vh;
                    padding: 150px 15px 0;
                    overflow-y: scroll;

                    .filter-content {
                        display: block;
                    }

                    .filter-title {
                        strong {
                            &:before {
                                @include lib-icon-svg($icon-base-close, $color-gray20);
                                height: 13px;
                                width: 13px;
                                position: absolute;
                                margin-left: -18px;
                                margin-top: 1px;
                                display: inline-block;
                            }
                        }
                    }

                    .smile-es-range-slider {
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product-info-main,
    .product-options-bottom {
        .box-tocart {
            display: table;

            .field {
                &.qty {
                    display: table-cell;
                }
            }

            .actions {
                display: table-cell;
                text-align: center;
                vertical-align: bottom;
            }
        }
    }

    .product-info-main {
        .page-title-wrapper {
            .page-title {
                margin-top: -13px;
            }
        }
    }

    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: auto;
                    margin: 0 $indent__s $indent__s 0;
                    position: relative;
                    top: auto;
                }
            }

            .product-item-details {
                margin: 0;
            }

            .product-item-actions {
                clear: left;
            }
        }
    }

    .product-add-form {
        @extend .abs-revert-field-type-desktop;
    }

    .brands-container {
        &.no-thumbnails {
            bottom: 20px;
        }

        &.have-thumbnails {
            bottom: 120px;
        }

        .brand-image {
            max-width: 125px;
        }
    }
}

//
//  Desktop large
//  _____________________________________________

@include min-screen($screen__xl) {
    .sidebar {
        .product-items {
            .product-item-info {
                .product-item-photo {
                    float: left;
                    left: 0;
                    margin: 0;
                    top: 0;
                }
            }
        }
    }
}

//
//  Category page layout
//  ---------------------------------------------

@include min-screen($screen__m) {
    .product-info-main {
        float: right;
    }

    .product {
        &.media {
            float: left;
            margin-bottom: $indent__m;
        }
    }

    .page-layout-1column {
        .product-info-main {
            width: 40%;
        }

        .product {
            &.media {
                width: 57%;
            }
        }
    }

    .page-layout-2columns-left,
    .page-layout-2columns-right,
    .page-layout-3columns {
        .product-info-main {
            width: 48%;
        }

        .product {
            &.media {
                width: 50%;
            }
        }
    }
}

//
//  Compare Products Page
//  ---------------------------------------------

body {
    &.catalog-product-compare-index {
        .action {
            &.print {
                float: right;
                margin: 15px 0;
            }
        }
    }
}

.table-wrapper {
    &.comparison {
        clear: both;
        max-width: 100%;
        overflow-x: auto;
    }
}

.table-comparison {
    table-layout: fixed;

    .cell {
        &.label {
            &.remove,
            &.product {
                span {
                    @extend .abs-visually-hidden;
                }
            }
        }
    }

    td {
        &:last-child {
            border-right: $table__border-width $table__border-style $table__border-color;
        }
    }

    .cell {
        padding: 15px;
        width: 140px;

        .attribute {
            &.value {
                overflow: hidden;
                width: 100%;
            }
        }

        &.product {
            &.info,
            &label {
                border-bottom: $table__border-width $table__border-style $table__border-color;
            }
        }

        &.label {
            border-right: $table__border-width $table__border-style $table__border-color;

            .attribute {
                &.label {
                    display: block;
                    width: 100%;
                    word-wrap: break-word;
                }
            }
        }

        &.attribute {
            @include lib-font-size($font-size__s);

            img {
                height: auto;
                max-width: 100%;
            }
        }
    }

    .product-item-photo {
        display: block;
        margin: 0 auto 15px;
    }

    .product-image-photo {
        margin-left: 0;
    }

    .product-item-actions,
    .price-box,
    .product-item-name {
        display: block;
        margin: 15px 0;
    }

    .product {
        &.rating {
            display: block;
            margin: 15px 0;
        }
    }

    .product-addto-links {
        margin-top: 15px;

        .action {
            &.split,
            &.toggle {
                @include lib-button-s();
            }
        }

        .action {
            &.toggle {
                padding: 0;
            }
        }
    }

    .cell {
        &.remove {
            padding-bottom: 0;
            padding-top: 0;
            text-align: right;

            .action {
                &.delete {
                    @extend .abs-remove-button-for-blocks;
                }
            }
        }
    }

    .product-item-actions {
        > .actions-primary {
            + .actions-secondary {
                margin-top: $indent__s;
            }
        }
    }

    .action {
        &.tocart {
            white-space: nowrap;
        }
    }
}

.comparison {
    &.headings {
        @include lib-css(background, $page__background-color);
        left: 0;
        position: absolute;
        top: 0;
        width: auto;
        z-index: 2;
    }
}

//
//  Filters on category view
//  ---------------------------------------------

.catalog-category-view {
    .page {
        &.messages {
            display: none;
        }
    }

    .page-title-wrapper {
        .page-title {
            color: $catalog-title-color-dark;
            margin-bottom: 7px;

            span {
                @include lib-font-size($font-size__xxxl);
                @include lib-css(font-weight, $font-weight__bold);
            }
        }
    }

    .sidebar {
        .block {
            &-title {
                border: none;
                margin-bottom: 0;

                > strong {
                    @include lib-heading(h4);
                    margin: 0 0 $indent__s;
                    word-break: break-all;
                }
            }

            &-compare {
                .product-item-name {
                    margin-left: 22px;
                }

                .action {
                    &.delete {
                        @extend .abs-remove-button-for-blocks;
                        left: -6px;
                        position: absolute;
                        top: 0;
                    }

                    &.compare {
                        @extend .abs-revert-secondary-color;
                    }

                    &.primary {
                        @include lib-button-checkout();
                    }
                }

                .counter {
                    @extend .abs-block-items-counter;
                }

                .actions-toolbar {
                    margin: 17px 0 0;

                    .secondary {
                        float: left;
                    }
                }
            }
        }
    }
}

.sorter-action {
    &:before {
        display: none;
    }
}

.product-list {
    &.usp-bar {
        border: 0;
        padding: 0;
        margin: 10px 0 40px;
        background: $theme__secondary__color;
        @include lib-vendor-prefix-display(flex);

        .row {
            width: 100%;
            margin: 0;
        }

        ul {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            justify-content: space-evenly;
            list-style: none;
            margin: 0;
            padding: 15px 0;
            width: 100%;
        }

        li {
            @include lib-vendor-prefix-display(flex);
            justify-content: center;
            margin-bottom: 0 !important;
            width: auto;
        }

        span {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            @include lib-font-size($font-size__s);
            font-weight: $font-weight__heavier;
        }
    }
}

.block-viewed-products-grid {
    width: 100%;
    background-color: $color-gray96;
    margin-bottom: 0;

    .block-title {
        max-width: 1280px;
        margin: 0 auto;
        padding-top: 40px;
    }

    .block-content {
        max-width: 1280px;
        margin: 0 auto;
        padding: 0 20px 62px;
    }

    .actions-primary {
        width: 100%;
    }
}

.block-products-list {
    &.grid {
        .block-content {
            padding-top: 30px;
            padding-bottom: 70px;
            display: inline-block;
        }
    }
}

.stock {
    @include lib-css(font-family, $font-family__base);
    font-weight: $font-weight__regular;
    margin-bottom: 6px;

    &.customizable-product,
    &.available {
        &:before {
            content: url(lib-icon-svg-url($icon-kuras-check, $theme__secondary__color));
            width: 18px;
            height: 18px;
            margin-right: 12px;
            display: inline-block;
        }
    }

    &.unavailable {
        color: $color-orange1;

        &:before {
            @include lib-icon-svg($icon-base-check-circle, $color-orange1);
            width: 12px;
            margin-right: 7px;
            display: inline-block;
        }
    }

    &.request {
        color: $color-gray64;

        &:before {
            content: url(lib-icon-svg-url($icon-kuras-check-white, $color-gray64));
            width: 18px;
            height: 18px;
            margin-right: 12px;
            display: inline-block;
        }
    }
}

@include max-screen($screen__xxs + 1) {
    .product-info-main {
        .box-tocart {
            .action {
                &.tocart {
                    span {
                        @include lib-font-size($font-size__s);
                        padding-right: 25px;
                    }
                }
            }
        }
    }
}

@include max-screen($screen__m) {
    .product-image-photo {
        margin: 0 auto;
    }
    .product-list {
        &.usp-bar {
            ul {
                @include lib-vendor-prefix-flex-direction(column);
            }

            li {
                width: 100%;
                margin-bottom: 10px !important;
                justify-content: flex-start;
            }
        }
    }

    .page-wrapper {
        .product-info-details-container {
            ul {
                &.tab-group {
                    li {
                        &:last-of-type {
                            margin-right: 15px;
                        }

                        a {
                            @include lib-font-size($font-size__s);
                        }
                    }
                }
            }
        }
    }
}

.no-thumbnails {
    .fotorama__arr--next,
    .fotorama__arr--prev {
        display: none !important;
    }
}

@include min-screen($screen__l) {
    .product-info-main {
        .box-tocart {
            .actions {
                width: calc(100% - 223px);
            }

            .action {
                &.tocart {
                    margin-left: 23px;
                }
            }
        }
    }
}

@include min-screen($screen__lg) {
    .catalog-product-view {
        .sidebar-info {
            padding-right: 0;
        }
    }
}

@include max-screen($screen__lg) {
    .catalog-product-view {
        .sidebar-info {
            padding: 0;
        }
    }
}

.gallery-placeholder {
    &:not(._block-content-loading) {
        .gallery-placeholder__image {
            display: none;
        }
    }
}
