.homepage-blok {
    .subtitle {
        &.kiyoh-container {
            @include lib-vendor-prefix-display(flex);
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__l);
            @include lib-css(font-weight, $font-weight__semibold);
            line-height: $line-height__xl;
            background: $color-white;
            border-top: 1px solid $theme__primary__border__color;
            position: relative;
            padding: 0;
            height: 70px;
            align-items: flex-end;

            > div {
                width: 100%;
            }

            a {
                @include lib-font-size($font-size__base);
                line-height: $line-height__l;
                color: $color-white;

                .highlight {
                    line-height: $line-height__xs;
                    color: $color-gray20;
                }

                .fa {
                    display: none;
                }
            }

            .usp-container-rating {
                @include lib-font-size($font-size__highlight);
                line-height: $line-height__xs;
                position: absolute;
                display: block;
                right: 0;
                top: 6px;
                height: auto;
                padding-top: 0;
                padding-right: 16px;

                span {
                    position: absolute;
                    margin-left: -5px;
                    @include lib-font-size($font-size__highlight);
                    line-height: $line-height__xs;
                }

                sup {
                    top: -15px;
                    font-size: 60%;
                    margin-left: 3px;
                }
            }
        }
    }
}

.footer {
    &-kiyoh-wrapper {
        color: $color-gray20;
        @include lib-css(font-family, $font-family__base);
        text-align: center;
    }
}