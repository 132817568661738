.homepage {
    &-top-row {
        h1,
        h2 {
            color: $color-white;
        }

        p {
            color: $color-white;
            @include lib-font-size($font-size__m);
            font-weight: $font-weight__bold;
            margin-bottom: 3rem;
        }

        &.ves-container {
            .block-content {
                h1,
                h2 {
                    @include lib-font-size($font-size__xl);
                    font-weight: $font-weight__extrabold;
                }
            }
        }
    }

    &-banner {
        &-large,
        &-business {
            .background-cover {
                min-height: 180px;

                &.color-overlay {
                    &:after {
                        right: 15px;
                        left: 15px;
                    }
                }
            }
        }

        &-small {
            .background-cover {
                min-height: 150px;
                position: relative;

                &.color-overlay {
                    margin-top: 30px;

                    &:after {
                        right: 0;
                        left: 0;
                    }
                }

                &.business {
                    margin-top: 30px;

                    .block-content {
                        h2,
                        p {
                            color: $theme__primary__color;
                        }
                    }
                }
            }
        }

        &-large,
        &-small,
        &-business {
            .background-cover {
                padding: 5px 15px;
            }
        }

        &-business {
            margin-bottom: 15px;

            .block-content {
                h1,
                p {
                    color: $color-white;
                }

                h1 {
                    @include lib-font-size($font-size__xl);

                    &:after {
                        content: "";
                        display: block;
                        width: 63px;
                        padding-top: 27px;
                        border-bottom: 6px solid $theme__secondary__color;
                    }
                }

                p {
                    @include lib-font-size($font-size__m);
                }
            }
        }
    }
}

.background-cover {
    background-size: cover;
    overflow: hidden;

    &.color-overlay {
        &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            background-image: linear-gradient(to bottom left, $theme__primary__color, transparent);
            opacity: 0.8;
        }
    }
}

.pdp-banner-business,
.homepage-banner-business {
    .background-cover {
        &.color-overlay {
            &:after {
                background-image: linear-gradient(to right, $theme__primary__color, transparent);
            }
        }
    }
}

.ves-widget {
    position: relative;
    z-index: 1;
}

.latest-news {
    margin-top: 25px;
}

.homepage-tips-container {
    margin-bottom: 40px;

    .row-inner {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(stretch);

        .col-inner {
            height: 100%;
        }

        .ves-container {
            height: 100%;

            div {
                height: 100%;
            }
        }

        .ves-inner {
            height: 100%;
        }
    }

    .homepage-tips {
        min-height: 280px;
        color: $theme__primary__color;
        margin-top: 0;
        padding-right: 0;

        .row-inner {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(stretch);

            .img-container {
                position: relative;
                max-width: 300px;
                padding-right: 0;

                img {
                    height: 100%;
                }

                .arrow {
                    width: 45px;
                    height: 43px;
                    z-index: 1;
                    position: absolute;
                    right: -26px;
                    top: 30px;
                    overflow: auto;
                    left: inherit;
                    margin-left: inherit;
                    bottom: inherit;
                }

                .col-inner {
                    height: 100%;
                    background-size: cover;
                }
            }
        }
    }
}

.homepage-brand-slider {
    margin-bottom: 0;

    .widget-html,
    .block,
    .block-static-block.widget {
        margin-bottom: 0;
    }

    .block-content {
        overflow: hidden;

        ul {
            &.owl-carousel {
                margin-left: 0;
                margin-bottom: 0;
                padding-left: 0;
            }
        }

        li {
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            justify-content: center;
            background-color: $color-white;
            width: 180px;
            height: 180px;
            padding: 10px;
            margin-bottom: 10px;

            &:hover {
                box-shadow: 0 0 10px 0 $color-gray-light3;
            }
        }
    }

    .owl-stage {
        @include lib-vendor-prefix-display(flex);
        @include lib-vendor-box-align(center);
        padding: 10px 0 0;

        &-outer {
            padding: 0 5px;
        }

        .owl-item {
            padding: 0;

            img {
                filter: gray;
                -webkit-filter: grayscale(100%);
                filter: grayscale(100%);
                width: auto;
                margin: 0 auto;
            }

            &:hover {
                img {
                    filter: none;
                    -webkit-filter: none;
                }
            }
        }
    }
}

.read-more {
    @include lib-font-size($font-size__l);
    font-weight: $font-weight__extrabold;
    color: $theme__primary__color;
    position: relative;

    &:visited {
        color: $theme__primary__color;
    }

    .icon-arrow-right {
        top: 5px;
        right: -34px;
    }
}

.brand-title {
    .ves-widget.block {
        margin-bottom: 30px;
    }
}

.cms-page-view,
.cms-index-index {
    .homepage-usps {
        .horizontal-usps {
            .usp-container {
                margin-top: 35px;
                margin-bottom: 15px;
            }
        }
    }

    .help-block {
        margin-top: 0;

        .usp-container {
            .content {
                min-height: unset;

                ul {
                    padding: 15px 0;
                    list-style: none;

                    li {
                        background-image: none;
                        min-height: 40px;
                        padding-left: 19px;

                        a {
                            @include lib-font-size($font-size__m);
                            @include lib-css(font-weight, $font-weight__bold);
                            color: $primary__color;
                        }
                    }
                }
            }

            .usp-links {
                width: 45px;
                height: 45px;
                float: left;
            }
        }
    }

    .usp-container {
        margin-top: 0;

        .usp-title {
            @include lib-css(font-family, $font-family__base);
            @include lib-css(font-weight, $font-weight__semibold);
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            @include lib-font-size($font-size__xxl);
            line-height: $line-height__xs;
            background: $theme__primary__color;
            position: relative;
            padding: 0 32px;
            height: 80px;
            color: $color-white;
        }

        strong {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__m);
            @include lib-css(font-weight, $font-weight__bold);
            display: block;
            padding-bottom: 8px;
            line-height: $line-height__xs;
        }

        span {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            display: inline-block;
            padding-bottom: 4px;
            line-height: $line-height__s;
        }

        .content {
            background: $color-white;
            min-height: 260px;

            ul {
                @include lib-list-reset-styles();
                margin: 0 0 25px 0;

                li {
                    @include lib-css(font-family, $font-family__base);
                    @include lib-font-size($font-size__base);
                    height: auto;
                    padding-left: 30px;
                    margin-bottom: 8px;
                    line-height: $line-height__s;
                    font-weight: $font-weight__regular;
                    background-image: url(lib-icon-svg-url($icon-base-usp, $theme__secondary__color));
                    background-size: 18px 18px;
                    background-repeat: no-repeat;
                }
            }
        }

        .usp-links {
            text-indent: -9999px;
            display: inline-block;
            width: 70px;
            height: 70px;
            margin-left: 2px;
            margin-right: 15px;
            float: right;
        }

        &.homepage-menu {
            .usp-title {
                height: 60px;
            }

            .content {
                padding: 0;

                li {
                    padding: 0;
                    background: none;
                    margin: 0;
                    border-bottom: 2px solid $page__background-color;

                    &:last-child {
                        border-bottom: 0;
                    }

                    a {
                        background: $color-white;
                        padding: 15px 29px;
                        display: block;
                        color: $primary__color;
                        font-size: $font-size__m;
                        position: relative;

                        &:hover {
                            background: $theme__secondary__color;
                            font-weight: $font-weight__bold;
                        }

                        &:after {
                            background: url(lib-icon-svg-url($icon-base-arrow-right, $primary__color)) no-repeat;
                            content: "";
                            height: 20px;
                            width: 27px;
                            display: inline-block;
                            right: 30px;
                            position: absolute;
                        }
                    }
                }
            }
        }
    }

    .ves-container.intro-text  {
        .block-content {
            h2 {
                margin-bottom: 30px;
            }
        }

        p {
            @include lib-font-size($font-size__l);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .homepage {
        &-top-row {
            &.ves-container {
                .block-content {
                    h1 {
                        @include lib-font-size($font-size__xxxl);
                        line-height: $line-height__s;
                    }

                    h2 {
                        @include lib-font-size($font-size__xxl);
                        line-height: $line-height__s;
                    }
                }
            }
        }

        &-banner {
            &-large {
                .background-cover {
                    height: 480px;
                }
            }

            &-business {
                .background-cover {
                    height: 400px;
                }
            }

            &-small {
                .background-cover {
                    height: 225px;

                    &.color-overlay {
                        margin-top: 0;
                    }

                    &.business {
                        margin-top: 30px;
                    }
                }
            }

            &-large,
            &-small {
                .background-cover {
                    padding: 5px 30px;
                }
            }

            &-business {
                margin-bottom: 75px;

                .background-cover {
                    padding: 45px 30px;
                }

                .block-content {
                    h1 {
                        @include lib-font-size($font-size__xxxl);
                    }

                    p {
                        @include lib-font-size($font-size__xl);
                        max-width: 40%;
                        padding-bottom: 30px;
                    }
                }
            }
        }
    }

    .ves-container.intro-text  {
        margin-top: 33px;
        margin-bottom: 55px;

        .block-content {
            h2 {
                margin-top: 0;
            }
        }

        .intro-content {
            padding-right: 75px;
        }
    }
}

//
//  tablet
//  _____________________________________________

@include screen($screen__m, $screen__l, $orientation: portrait) {
    .homepage {
        &-top-row {
            &.ves-container {
                .block-content {
                    h1,
                    h2 {
                        @include lib-font-size($font-size__xl);
                    }
                }
            }
        }

        &-banner {
            &-large,
            &-small {
                .background-cover {
                    padding: 0 15px;
                }
            }
        }
    }
}


//
//  phone
//  _____________________________________________

@include max-screen($screen__m) {
    .homepage-tips-container {
        > div {
            > .ves-inner {
                > .row {
                    > .row-inner {
                        @include lib-vendor-prefix-flex-direction(column);
                    }
                }
            }
        }

        .homepage-tip {
            padding-bottom: 20px;

            &:last-child {
                padding-bottom: 0;
            }

            .row-inner {
                @include lib-vendor-prefix-flex-wrap(wrap);
                padding-right: 15px;

                .img-container {
                    height: 30vw;
                    max-width: 100%;

                    .col-inner {
                        border: none;
                    }
                }
            }

            .content {
                margin-left: 15px;

                p {
                    margin-bottom: 60px;
                }

                a {
                    margin-left: 15px;
                }
            }
        }

        .row-inner {
            .ves-container {
                div {
                    height: unset;
                }
            }
        }
    }
}

@include max-screen($screen__xs) {
    .cms-page-view,
    .cms-index-index {
        .help-block {
            .usp-container {
                .content {
                    ul {
                        li {
                            padding-left: 10px;
                        }
                    }
                }
            }
        }
    }
}
