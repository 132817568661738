.form.wishlist.items {
    .actions-toolbar {
        @extend .abs-reset-left-margin;
    }
}

.wishlist-index-index {
    .products-grid {
        .product-item {
            border: 1px solid $theme__primary__border__color;
        }
    }

    .actions-toolbar {
        .primary {
            width: 100%;

            button {
                &.action {
                    width: 48%;

                    &.share {
                        @include lib-button-primary();
                        height: 45px;
                    }

                    &.tocart {
                        @include lib-button-checkout();
                        color: $theme__primary__color;
                        height: 45px;
                    }
                }
            }
        }
    }

    .sidebar-additional {
        margin-top: 0;
    }

    .product-item-name {
        min-height: 85px;
    }
}

.sidebar {
    .actions-toolbar {
        .primary {
            button {
                &.action {
                    &.tocart {
                        width: 100%;
                    }
                }
            }
        }
    }

    .block-wishlist {
        .product-item {
            margin: 10px 0;

            .product {
                &-item-info {
                    background-color: $color-white;
                    padding: 5px 0 0;
                    margin: 0;
                    border: 0;

                    .product-item {
                        &-actions {
                            margin-top: 0;

                            .actions-primary {
                                width: 70%;
                                display: inline-block;
                            }

                            .actions-secondary {
                                width: 25%;
                                float: right;
                                display: inline-block;

                                .action {
                                    &.delete {
                                        @include lib-button-primary();
                                        position: unset;
                                        width: 100%;
                                        padding: 12px;
                                        text-align: center;

                                        &:before {
                                            @include lib-icon-svg($icon-base-close, $color-white);
                                            width: 9px;
                                            height: 20px;
                                            margin-top: -10px;
                                        }

                                        &:hover {
                                            &:before {
                                                @include lib-icon-svg($icon-base-close, $theme__primary__color);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        &-name {
                            padding: 0 15px;
                            min-height: unset;
                        }
                    }
                }

                &-item-details {
                    border-top: 0;
                    padding: 0;
                    min-height: unset;
                }
            }

            &:hover {
                .product-item {
                    &-info {
                        box-shadow: none;
                        border: 0;
                    }

                    &-actions {
                        display: inline-block;
                        position: unset;
                        width: 100%;
                        left: 0;
                        z-index: 100;
                        margin-top: 0;
                        margin-left: 0;
                        border: 0;
                        box-shadow: none;
                    }
                }
            }

            .primary {
                border-width: 2px;
                border-style: solid;
                padding: 12px;
            }

            .product-image-container {
                padding: 0;
            }
        }

        .product-items {
            .product-item-info {
                .product-item-photo	{
                    display: block;
                    float: none;
                }
            }

            .price-box {
                padding: 0 15px;
                margin-bottom: 10px;
                display: inline-block;
            }
        }
    }
}

.product-items,
.products.list.items {
    .action {
        &.towishlist {
            float: left;
            margin-left: 23px;
            margin-top: 18px;
            z-index: 1;
            position: relative;

            span {
                padding-left: 8px;
            }

            &:before {
                @include lib-icon-svg($icon-base-rating-star-empty, $color-gray-light9);
                display: inline-block;
                width: 18px;
                height: 18px;
                background: transparent;
            }

            &:hover,
            &.is-favorite {
                &:before {
                    @include lib-icon-svg($icon-base-rating-star, $theme__secondary__color);
                }
            }
        }
    }
}

.product-info-main {
    .product-addto-links {
        .action {
            &.towishlist {
                padding-left: 0;
                display: flex;
                align-items: center;
                text-decoration: none;

                span {
                    padding-left: 8px;
                }

                &:before {
                    @include lib-icon-svg($icon-base-rating-star-empty, $color-gray-light9);
                    display: inline-block;
                    width: 18px;
                    height: 18px;
                    background: transparent;
                }

                &:hover,
                &.is-favorite {
                    &:before {
                        @include lib-icon-svg($icon-base-rating-star, $theme__secondary__color);
                    }
                }
            }
        }
    }
}

.abs-actions-addto-gridlist {
    position: absolute;
    right: 20px;
    top: 11px;
    z-index: 99;
    width: 21px;
    height: 21px;

    &:before {
        @include lib-icon-svg($icon-base-rating-star-empty, $color-gray-light9);
        display: inline-block;
        width: 23px;
        height: 22px;
        background: transparent;
    }

    &:hover {
        &:before {
            @include lib-icon-svg($icon-base-rating-star, $theme__secondary__color);
        }
    }
}

.account .table-wrapper .data.table.wishlist {
    @include lib-table-bordered(
        $_table_type: horizontal
    );

    thead > tr > th {
        border-bottom: 0;
    }

    tbody > tr:last-child > td {
        border-bottom: 1px solid $table__border-color;
    }

    .product.name {
        display: inline-block;
        margin-bottom: $indent__s;
    }

    .box-tocart {
        margin: $indent__s 0;

        .qty {
            @extend .abs-input-qty;
            vertical-align: middle;
        }
    }

    .col {
        &.item {
            width: 50%;
        }

        &.photo {
            max-width: 150px;
        }

        &.selector {
            max-width: 15px;
        }
    }

    textarea {
        margin: $indent__s 0;
    }

    .input-text.qty {
        margin-bottom: $indent__s;
    }

    .action.primary {
        vertical-align: top;
    }

    .price {
        @include lib-css(font-weight, $font-weight__bold);
    }
}

.block-wishlist {
    .block-title {
        @include lib-font-size($font-size__base);
    }

    .counter {
        @extend .abs-block-items-counter;
    }

    .product-item-name {
        margin-right: $indent__m;
    }
}

.products-grid.wishlist {
    .product {
        &-item {
            &-photo {
                display: block;
                margin-bottom: $indent__s;
            }

            .price-box {
                margin: 0;
                padding: 0;
            }

            .comment-box {
                .label {
                    @extend .abs-visually-hidden;
                }
            }

            &-comment {
                display: block;
                height: 42px;
                margin: $indent__s 0;
            }

            &-actions {
                > * {
                    margin-right: 15px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            &-info {
                .btn-remove {
                    padding: 0 25px 0;
                }
            }

            .box-tocart {
                input.qty {
                    @extend .abs-input-qty;
                    height: 32px;
                }
            }
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__s) {
    .products-grid.wishlist {
        .product-item {
            border-bottom: 1px solid $secondary__color;
            &:first-child {
                border-top: 1px solid $secondary__color;
            }
        }
    }
}

@include max-screen($screen__m) {
    .products-grid.wishlist {
        margin-bottom: $indent__l;

        .product {
            &-item {
                padding: 0;
                position: relative;

                &-photo {
                    float: left;
                    margin-right: $indent__base;
                }

                &-name {
                    @include lib-font-size(16);
                }

                &-actions {
                    display: block;

                    .action {
                        margin-right: 15px;

                        &:last-child {
                            margin-right: 0;
                        }

                        &.edit {
                            float: left;
                        }

                        &.delete {
                            float: right;
                        }

                        &.edit,
                        &.delete {
                            margin-top: 7px;
                        }
                    }
                }

                &-name,
                &-description,
                .price-box,
                &-tooltip {
                    margin-left: 95px;
                }

                .box-tocart {
                    .stock {
                        margin-top: 7px;
                    }
                }

                .giftregisty-dropdown {
                    display: none;
                }
            }

            &-image-container {
                max-width: 80px;
            }
        }
    }

    //
    //  Grid view for wishlist
    //  -----------------------------------------

    .wishlist-index-index {
        .product {
            &-item {
                width: 100%;
                &-info {
                    width: auto;
                }
            }
        }

        .actions-toolbar {
            .primary {
                button {
                    &.action {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@include min-screen($screen__s) {
    .wishlist-index-index {
        .products-grid {
            .product-item {
                margin-bottom: $indent__base;
                position: relative;
            }

            .product-item-actions {
                margin: 0;
                position: unset;
                display: block;
                z-index: 1;
                border: none;
                border-top: 0;
                box-shadow: none;
                margin-top: -2px;
                padding: 0;
                width: 100%;
            }
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .products-grid.wishlist {
        .product {
            &-item {
                &-tooltip {
                    display: inline-block;
                }

                &-actions {
                    margin: $indent__s 0 0;
                }

                .fieldset {
                    .field.qty {
                        .label {
                            width: auto;
                        }
                    }
                }

                .box-tocart {
                    .actions-primary {
                        margin: 0;
                    }

                    .stock {
                        margin: $indent__base 0 0;
                    }
                }
            }
        }
    }

    .wishlist-index-index {
        .main {
            .form-wishlist-items {
                .actions-toolbar {
                    @extend .abs-reset-left-margin-desktop;
                }
            }
        }
    }
}

@include min-screen($screen__l) {
    .wishlist-index-index {
        .products-grid {
            .product-items {
                margin: 0;
            }

            .product-item {
                &:nth-child(4n + 1) {
                    margin-left: 0;
                }
            }
        }
    }
}
