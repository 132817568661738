.cms-page-view {
    &.cms-kruiningen {
        .breadcrumbs {
            display: none;
        }

        #maincontent {
            &.page-main {
                margin-top: 180px;
            }
        }
    }

    .locations {
        .ves-container {
            p,
            .intro-content {
                font-size: 16px;
            }
        }

        .intro-block {
            margin-left: -24rem;
            z-index: 9;
        }

        a {
            text-decoration: underline;

            &.banner-button,
            .banner-button-secondary {
                text-decoration: none;
            }
        }

        .sub-title {
            text-transform: uppercase;
            font-weight: 700;
        }

        h1 {
            &.title {
                font-size: 32px;
                margin-top: 5px;
                margin-bottom: 20px;
            }
        }

        .title-small {
            font-size: 32px;
            margin-bottom: 20px;
            margin-top: 5px;
        }
    }
}

.equalheight {
    @include lib-vendor-prefix-display(flex);
    flex-wrap: wrap;
}

.equal-centered-content {
    @include lib-vendor-prefix-display(flex);
    @include lib-vendor-box-align(center);
}

@include max-screen($screen__lg) {
    .cms-page-view {
        .locations {
            .intro-block {
                margin-left: initial;
            }
        }
    }
}
