@include max-screen($screen__m) {
  .block {
    &.widget {
      &.blog-search {
        input {
          height: 40px;
          padding-left: 12px;
          margin-bottom: 19px;
        }
      }
    }
  }
}