@use 'sass:math';

.fotorama__arr__arr {
    background-color: $theme__primary__color;

    .fotorama__arr & {
        @extend .fotorama-sprite;
        width: $size-fotorama-block;
        height: $size-fotorama-block;
        @include fotorama-abs-center();

        .ie9 & {
            margin: math.div(-$size-fotorama-block, 2) 0 0 math.div(-$size-fotorama-block, 2);
        }
    }

    .fotorama__arr--prev & {
        background-position: -90px -95px;
    }

    .fotorama__arr--next & {
        background-position: -180px -95px;
    }
}
