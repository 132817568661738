.page-header {
    .shop-review-wrapper {
        &.small {
            margin-top: 12px;

            .rating {
                padding-bottom: 0;
            }

            a {
                color: $color-white;
            }
        }
    }
}

.footer {
    .shop-review-wrapper {
        .rating-score {
            color: $primary__color;
            background-color: $theme__secondary__color;
        }

        .shop-review-item {
            border-top: 1px solid $color-gray40;
            border-bottom: 1px solid $color-gray40;
        }

        .rating-footer {
            justify-content: space-between;
            width: 100%;

            .review-link {
                color: $color-white;
            }
        }

        .shop-review-item,
        a {
            color: $color-white;
        }

        a {
            text-decoration: underline;
        }
    }
}
