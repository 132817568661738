.table {
  &.data {
    &.grouped {
      > thead {
        > tr {
          > th {
            border-bottom: none;
          }
        }
      }

      > tbody {
        border: none;
      }

      .product-item-name {
        font-size: $font-size__m;
        font-weight: $font-weight__regular;
        margin: 0 0 5px;
      }

      .price-box {
        margin-top: 0;

        .price {
          font-size: $font-size__m;
          color: $theme__primary__color;
        }

        .old-price {
          color: $color-gray20;

          span {
            font-size: $font-size__base;
            color: $color-gray20;
          }
        }
      }

      .stock {
        &.unavailable {
          font-weight: normal;
          margin: 0;
          text-transform: none;
          white-space: nowrap;
        }
      }

      .row-tier-price {
        td {
          border-top: 0;
        }

        .prices-tier {
          margin: 0 -10px 10px;
        }
      }

      .col {
        &.qty {
          width: 100px;
        }
      }
    }
  }
}

.page-product-grouped {
  .product-info-main {
    .box-tocart {
      display: inline-block;

      .actions {
        width: 100%;
      }
    }
  }
}

@include min-screen($screen__xl) {
  .page-product-grouped {
    .product-info-main {
      .box-tocart {
        width: 75%;
      }
    }
  }
}

.product-info-main-wrapper {
  &.fixed {
    .table-wrapper {
      &.grouped {
        display: none;
      }
    }
  }
}

@include max-screen($screen__m) {
  .page-product-grouped {
    .product-info-main {
      .box-tocart {
        width: 100%;
      }
    }

    .table-wrapper {
      .table {
        &:not(.cart) {
          &:not(.totals) {
            &:not(.table-comparison) {
              > tbody > tr td {
                &:before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }
}

.page-product-grouped,
.page-product-bundle {
  .qty-plus,
  .qty-minus {
    width: 33.33%;
    height: 43px;
    float: left;
    cursor: pointer;
    text-align: center;

    div {
      width: 12px;
      height: 43px;
      display: inline-block;
    }
  }

  .qty-plus {
    div {
      background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray64)) no-repeat center center;
    }

    &:hover {
      div {
        background: url(lib-icon-svg-url($icon-base-add-to-cart, $color-gray23)) no-repeat center center;
      }
    }
  }

  .qty-minus {
    div {
      background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray64)) no-repeat center center;
    }

    &:hover {
      div {
        background: url(lib-icon-svg-url($icon-base-remove-from-cart, $color-gray23)) no-repeat center center;
      }
    }
  }

  .control {
    &.qty {
      width: 100%;
      margin: 0;
      height: 45px;
      border: 1px solid $catalog-border-color;

      .input-text {
        &.qty {
          @include lib-font-size($font-size__m);
          @include lib-css(font-weight, $font-weight__semibold);
          width: 33.33%;
          height: 43px;
          float: left;
          border: 0;
          vertical-align: top;
          margin: 0;
          padding: 0;
          color: $catalog-text-color;
          text-align: center;
        }
      }
    }
  }
}