.mageworx_htmlsitemap-index-index {
    .page-main {
        margin-top: 180px;
    }
}

p {
    a {
        text-decoration: underline;
    }
}

.cms-page-view,
.catalog-product-view {
    .usp-container {
        .usp-title {
            @include lib-css(font-family, $font-family__base);
            @include lib-css(font-weight, $font-weight__semibold);
            @include lib-vendor-prefix-display(flex);
            @include lib-vendor-box-align(center);
            @include lib-font-size($font-size__xl);
            line-height: $line-height__xs;
            background: $theme__primary__color;
            position: relative;
            padding: 0 26px;
            height: 70px;
            color: $color-white;
        }

        strong {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__m);
            @include lib-css(font-weight, $font-weight__bold);
            display: block;
            padding-bottom: 8px;
            line-height: $line-height__xs;
        }

        span {
            @include lib-css(font-family, $font-family__base);
            @include lib-font-size($font-size__base);
            @include lib-css(font-weight, $font-weight__regular);
            display: inline-block;
            padding-bottom: 4px;
            line-height: $line-height__s;
        }
    }

    .customer-service {
        background-color: $theme__secondary__color;
        color: $theme__primary__color;
        margin-top: 0;
        overflow: hidden;
        margin-bottom: 30px;

        .help-block {
            margin-top: 0;
        }

        ul {
            padding: 15px 0 1px;
            list-style: none;
            margin-bottom: 0;

            li {
                @include lib-vendor-prefix-display(flex);
                @include lib-vendor-box-align(center);
                background-image: none;
                height: 40px;
                padding-left: 10px;


                a {
                    @include lib-css(font-weight, $font-weight__bold);
                    color: $theme__primary__color;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .usp-links {
            width: 45px;
            height: 45px;
            float: left;
        }
    }

    .usps-sidebar {
        .usp-container {
            margin-top: 0;

            .content {
                background: $color-white;
                min-height: 260px;

                ul {
                    @include lib-list-reset-styles();
                    padding: 25px;

                    li {
                        @include lib-css(font-family, $font-family__base);
                        @include lib-font-size($font-size__base);
                        height: auto;
                        padding-left: 30px;
                        margin-bottom: 8px;
                        line-height: $line-height__s;
                        font-weight: $font-weight__regular;
                        background-image: url(lib-icon-svg-url($icon-base-usp, $theme__secondary__color));
                        background-size: 18px 18px;
                        background-repeat: no-repeat;
                    }
                }
            }

            .usp-links {
                text-indent: -9999px;
                display: inline-block;
                width: 70px;
                height: 70px;
                margin-left: 2px;
                float: right;
            }

            .subtitle {
                height: 70px;
                background-color: $page__detail__background-color;
                border-top: 1px solid $theme__primary__border__color;
            }
        }
    }
}

.usp-subline {
    color: $theme__primary__color;
}
.cms-page-view {
    .customer-service {
        padding: 0;
    }

    .brand-content {
        background-color: $color-white;
        padding: 22px 28px;

        .block-content {
            h2 {
                margin: 0 0 23px 0;
            }
        }
    }

    .view-more {
        @include lib-font-size($font-size__xl);
        @include lib-css(font-weight, $font-weight__semibold);
        display: block;
        padding-right: 40px;
        margin-top: 1.5rem;
        position: relative;


        .icon-arrow-right {
            background-color: transparent;
            width: 30px;
            margin-right: 15px;
            top: 2px;
            right: unset;
        }
    }
}

.pdp-banner-business {
    margin-bottom: 30px;

    .content {
        position: relative;
        z-index: 1;
        padding: 0 30px 20px;

        .banner-button-secondary {
            margin-top: 23px;
        }
    }

    h2,
    p {
        color: $color-white;
    }

    h2 {
        @include lib-font-size($font-size__xl);
        &:after {
            content: "";
            display: block;
            width: 63px;
            padding-top: 27px;
            border-bottom: 6px solid $theme__secondary__color;
        }
    }

    p {
        @include lib-font-size($font-size__m);
        @include lib-css(font-weight, $font-weight__bold);
    }

    .background-cover {
        min-height: 280px;
        position: relative;
        background-image: url('../images/zakelijke-afnemer-pdp.jpg');

        &.color-overlay {
            &:after {
                right: 0;
                left: 0;
            }
        }
    }
}

.zetwerk-banner-container-content {
    .full-background {
        .col-inner {
            min-height: 320px;
        }
    }
}

.formbuilder {
    input {
        &[type=text],
        &[type=password],
        &[type=tel],
        &[type=search],
        &[type=number],
        &[type=email] {
            width: 100%;
            height: 32px !important;
        }
    }
}

@include min-screen($screen__m) {
    .cms-zakelijk-inkoopaccount-aanvragen {
        .help-block {
            margin-top: 49px;
        }

        .block-title-wide {
            padding-top: 0;
            margin-top: 0;
        }

        .formbuilder-form {
            padding-bottom: 30px;
        }
    }
}

//
//  Phone
//  _____________________________________________

@include max-screen($screen__m) {
    .mageworx_htmlsitemap-index-index {
        .page-main {
            margin-top: 150px;
        }
    }

    .horizontal-usps {
        .usp-container {
            .usp {
                margin-bottom: 15px;
            }
        }
    }

    .cms-page-view {
        .page-main {
            .columns {
                margin-top: 175px;
            }
        }

        .help-block {
            margin-top: 30px;
        }
    }
}

//
//  tablet
//  _____________________________________________

@include screen($screen__m, $screen__l, $orientation: portrait) {
    .horizontal-usps {
        .usp-container {
            @include lib-vendor-prefix-flex-wrap(wrap);

            .usp {
                width: 50%;
                flex-shrink: 0;
                margin-bottom: 15px;
            }
        }
    }
}
