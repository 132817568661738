.formbuilder {
    label {
        font-weight: 700;
    }

    .subtemplate-wrapper {
        > label {
            margin-bottom: 0;
        }
    }

    .required {
        em {
            color: $color-gray20;
        }
    }

    .button {
        background: $theme__secondary__color;
        border: 2px solid $theme__secondary__color;
        color: $theme__primary__color;
        @include lib-css(font-family, $font-family__base);
    }

    .buttons-set {
        padding-top: 15px;
    }
}

.slide-panel-information {
    .button {
        &:hover {
            color: $theme__primary__color;
            background-color: $theme__secondary__color;
            border-color: $theme__secondary__color;
            transition: all 0.2s ease-out;
        }
    }
}